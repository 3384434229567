export const unset = (obj, path) => {
    if (!obj || !path || path.length === 0) {
        return;
    }

    const keys = path.split('.');

    for (let i = 0; i < keys.length - 1; i++) {
        obj = obj[keys[i]];

        if (obj === undefined || obj === null) {
            return; // Property path not found
        }
    }

    const lastKey = keys[keys.length - 1];
    if (obj.hasOwnProperty(lastKey)) {
        delete obj[lastKey];
    }
}