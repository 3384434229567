import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import {
  Card,
  CardContent,
  Grid,
  InputBase,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
// import { ReactSortable } from 'react-sortablejs' // to delete
import { Media } from '../../../../../lib/artsyFresnel/Media'
import StageContainer from './SofaDrawingElements/StageContainer'
import SectionTitle from './SectionTitle'
import theme from '../../../../constants/theme'

const SofaModulesSelectWithDrawer = dynamic(
  () => import('./AditionalComponentGroup/SofaModulesSelectWithDrawer'),
  {
    ssr: false,
  },
)

const NoSSRSofaShapeSlider = dynamic(() => import('./SofaShapeSlider'), {
  ssr: false,
})
const NoSSRSofaShapeSliderMobile = dynamic(
  () => import('./SofaShapeSliderMobile'),
  {
    ssr: false,
  },
)

// import { SofaDrawingStage } from './SofaDrawingElements/SofaDrawingStage'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },

      fabricSelectionRow: {
        marginTop: '0px',
        marginBottom: '5px',
      },

      groupDescriptionCard: {
        display: 'flex',
        height: '100%',
        width: '100%',

        // [theme.breakpoints.down('lg')]: {
        //   height: '500px',
        // },
      },

      dragOverZone: {
        position: 'relative',
        background: 'yellow',
        width: '100%',
        height: '100%',
        minHeight: '600px',
      },

      dragOver: {
        background: 'green',
      },

      cardDescription: {
        marginTop: '10px',
        marginBottom: '10px',
      },

      cardContent: {
        minHeight: '600px',

        width: '50%',
        position: 'relative',
        // display: 'flex',
        // flexWrap: 'wrap',
        // flexDirection: 'row',
        // alignContent: 'space-between',
      },

      buttonBox: {
        // width: '100%',
        // height: '100%',
        // minHeight: '600px',
        position: 'relative',
        borderRight: '1px solid grey',
      },
      input: {
        border: '1px solid #d3d3d3',
        padding: theme.spacing(0, 0, 0, 1),
        marginTop: theme.spacing(1),
      },
      mainDrawingBox: {
        width: '100%',
        height: '100%',
        minHeight: '600px',
        position: 'relative',
      },
    }),
  { name: 'MuiSofaShapeSelect' },
)

export default function SofaShapeSelect({
  sofaForms,
  discount,
  showHeading = true,
  showModulesSlider = true,
  ...props
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])
  const chooseSofaShapeText = t('choose-sofa-shape-text')
  const searchInputPlaceholder = t('search-inputPlaceholder')

  const sofaConfigurationText = t('sofa-configuration-text')
  const sofaConfigurationExplanationText = t(
    'sofa-configuration-explanation-text',
  )
  // const sofaConfigurationExplanationText =
  //   'Šioje vietoje matote visas galimas sofos dalis. Norėdami pradėti konfigūruoti sofą norimą dalį tempkite ant žemiau esančio lauko.'

  // ------ STATE -----
  const [clickeSofaShapes, setClickeSofaShapes] = useState([])
  const clickedSofaShapeIds = clickeSofaShapes.map((item) => item.id)
  const [selectedFabricGroup, setSelectedFabricGroup] = useState(null)
  const [dropables, setDropables] = useState([])
  const dropablesIds = dropables.map((item) => item.id)

  useEffect(() => {
    // We need to reset dropables
    console.log('SofaForms changed, resetting dropables')
    setDropables([])
  }, [sofaForms])

  // ===== DRAG AND DROP ========
  const handleDragEnd = (event) => {
    const { active, over } = event

    let uniqueID = new Date().valueOf()
    const newObject = {
      id: uniqueID.toString(),
      sofaForm: active.data.current.sofaForm,
    }

    if (over) {
      // -----
      let newDropables = [...dropables, newObject]
      setDropables(newDropables)
    }

    // if (over && over.data.current.accepts.includes(active.data.current.type)) {
    //   // do stuff
    // }
  }

  interface SofaForm {
    id: string
    name: string
  }

  interface Dropable {
    id: string
    sofaForm: SofaForm
  }

  const addSofaToStage = (sofaForm: SofaForm) => {
    console.log('Trying to add sofa form...>')
    // console.log('sofaForm :>> ', sofaForm)

    let uniqueID = new Date().valueOf()
    const newObject: Dropable = {
      id: uniqueID.toString(),
      sofaForm: sofaForm,
    }

    let newDropables = [...dropables, newObject]

    //console.log('newDropables :>> ', dropables, newDropables)
    setDropables((dropables) => [...dropables, newObject])
    updateDropables()
  }

  const updateDropables = () => {
    console.log('updateDropables', dropables)
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const stageCanvasRef = useRef(null)

  const onSofaDelete = (id) => {
    //---
    // console.log('Deleting sofa id->', id)
    const dropableIndex = dropablesIds.indexOf(id)
    let newDropables = [...dropables]
    newDropables.splice(dropableIndex, 1)

    console.log('newDropables  delete:>> ', newDropables)
    // newDropables[dropableIndex].sofaForm = null
    setDropables(newDropables)
  }

  // Automaticaly add sofa to stage if there is only one sofa form, mostly used for Fotels

  useEffect(() => {
    if (dropables.length === 0 && sofaForms.length === 1) {
      addSofaToStage(sofaForms[0])
    }
  }, [dropables, sofaForms])

  // if there is only one sofa form, automaticaly, the stage is hidden
  // if (!sofaForms || sofaForms.length === 1)
  //   return (
  //     <Grid item lg={12} sx={{ width: '100%' }}>
  //       <Card>
  //         <DroppableBox id="main_box" style={{ height: 0 }}>
  //           <StageContainer
  //             sofaShapes={dropables}
  //             onSofaDelete={onSofaDelete}
  //           />
  //         </DroppableBox>
  //       </Card>
  //     </Grid>
  //   )
  // End of automaticaly add sofa to stage if there is only one sofa form, mostly used for Fotels

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <Grid container justifyContent="space-evenly" spacing={0}>
        {showHeading && (
          <>
            <SectionTitle title={sofaConfigurationText} />
            <Typography
              variant="body1"
              component="p"
              sx={{ marginLeft: '30px' }}
            >
              {sofaConfigurationExplanationText}
            </Typography>
          </>
        )}
        <SofaModulesSelectWithDrawer
          discount={discount}
          sofaForms={sofaForms}
          onAddFormClick={addSofaToStage}
        />
        <Grid
          container
          justifyContent="space-evenly"
          spacing={0}
          className={classes.fabricSelectionRow}
        >
          {showModulesSlider && sofaForms?.length > 1 && (
            <Grid item lg={12} sx={{ width: '100%' }}>
              <Media greaterThan="md">
                <NoSSRSofaShapeSlider
                  items={sofaForms}
                  selectedItem={null}
                  setSelectedItem={null}
                  discount={discount}
                  onChange={addSofaToStage}
                />
              </Media>

              <Media lessThan="lg">
                <NoSSRSofaShapeSliderMobile
                  items={sofaForms}
                  selectedItem={null}
                  setSelectedItem={null}
                  discount={discount}
                  onChange={addSofaToStage}
                />
              </Media>
            </Grid>
          )}

          <Grid
            item
            lg={12}
            sx={{
              width: '100%',
              marginRight: '16px',
              backgroundColor: 'white',
            }}
          >
            <DroppableBox id="main_box">
              <StageContainer
                sofaShapes={dropables}
                onSofaDelete={onSofaDelete}
              />
            </DroppableBox>
          </Grid>
        </Grid>
      </Grid>
    </DndContext>
  )
}

const DroppableBox = ({ id, ...props }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: id,
  })

  const style = {
    width: '100%',
    height: '100%',
    background: isOver ? '#2cff0021' : 'none',
    // display: 'flex',
    // alignItems: 'center',
    // justifyItems: 'center',
    // justifyContent: 'center',
    ...props.style,
  }

  return (
    <div ref={setNodeRef} style={style} className={props.className}>
      {props.children}
    </div>
  )
}
