import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { Box, LinearProgress, Theme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { parse } from 'next-useragent'
import React, { useEffect, useState } from 'react'
import { addToRecentlyViewed } from '../../operations/clientMutations/recentlyViewedClient'
import { GET_ADDITIONAL_PRODUCT_DATA } from '../../operations/queries'
import {
  recentlyViewedVar,
  resetAdvancedProductVars,
} from '../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../utilityFunctions/getProfile'
import { TopProductPage } from '../DesktopElements/ProductPageElements'
import ContentBlock from '../DesktopElements/ProductPageElements/ContentBlock'
import { ProductSlider } from '../DesktopElements/Utils'
import CustomSection from '../DesktopElements/Utils/CustomSection'
import { useAppContext } from '../../context/appContext'
import AdvancedProductPage from '../DesktopElements/ProductPageElements/AdvancedProductElements/AdvancedProductPage'
import { getProductDataForProductPage2 } from '../../operations/clientMutations/getProductDataForProductPage'
import { makeStyles } from '@mui/styles'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    // maxWidth: 1920,
    margin: '10',
  },
}))

export default function ProductPage({ productPermalink, productData }) {
  const { b2bShowOnlySelectedProducts, priceListId, palettes } = useAppContext()
  const { locale } = useRouter()
  const [data, setData] = useState(!priceListId ? productData : null)

  useEffect(() => {
    const fetchProductData = async () => {
      if (priceListId) {
        const newProductData = await getProductDataForProductPage2(
          productPermalink,
          priceListId,
          palettes,
          false,
          locale,
        )
        setData(newProductData)
      }
    }

    fetchProductData()
  }, [priceListId, productPermalink])

  // === RESET REACTIVE VARS
  useEffect(() => {
    // --- We need to initiate VAR reset on every page
    setData(null)
    resetAdvancedProductVars()
    return () => {
      resetAdvancedProductVars()
    }
  }, [productPermalink])

  if (data?.single_product) {
    return (
      <SimpleProductPage
        productPermalink={productPermalink}
        productData={data}
      />
    )
  } else if (data?.advanced_product) {
    return (
      <AdvancedProductPage
        productPermalink={productPermalink}
        productData={data}
      />
    )
  } else {
    // unhandlend entry
    console.warn('Anhandled product type (meta information flow broken)')

    return (
      <EmptyProductPage
        productPermalink={productPermalink}
        productData={data}
      />
    )
  }
}

const EmptyProductPage = ({ productPermalink, productData }): JSX.Element => {
  const classes = useStyles() as any
  const { t } = useTranslation(['common'])
  const userAgent = null
  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  //
  return (
    <div className={classes.container}>
      <LinearProgress />
      <div style={{ padding: '10px' }}>{t('loading-text')}</div>
    </div>
  )
}

function SimpleProductPage({ productPermalink, productData }) {
  const { t } = useTranslation(['common'])
  const recentlyViewedString = t('recently-viewed-carousel-header')
  const moreProductsString = t('more-products')
  const recentlyViewed = useReactiveVar(recentlyViewedVar)
  const { locale, b2bShowOnlySelectedProducts } = useAppContext()

  // -- UA in AppContext is deprecated...
  // const appContext = useAppContext()
  // const userAgent = appContext ? appContext.userAgent : null
  const userAgent = null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false;

  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  const currentProductID = productData?.id ?? null

  // We get all categories of the prouct
  const currentProductCategories =
    productData?.categories.map((item) => item.id) ?? []

  // We check for primary category
  const currentProductPrimaryCategory =
    productData?.primary_category?.id ?? null

  // We form a list of primary category, if it doesnt exist then from all categories
  const categoriesListForQuery = currentProductPrimaryCategory
    ? [currentProductPrimaryCategory]
    : currentProductCategories

  const {
    loading: sameCategoryProductsLoading,
    data: sameCategoryProductsData,
  } = useQuery(GET_ADDITIONAL_PRODUCT_DATA, {
    variables: {
      language: locale,
      productCategories: categoriesListForQuery,
    },
  })

  const sameCategoryProducts =
    sameCategoryProductsData?.findManyProductContainer ?? []

  const modifiedRecentlyViewed = recentlyViewed
    .slice()
    .filter((item) => item.id != currentProductID)

  // ======================== DATA FROM STATIC PROPS
  const product = productData.single_product.product_profile

  // ====== PRODUCT STYLE ========
  const productStyle = productData.style ?? null
  const styleProfileIndex = productStyle
    ? getProfileIndexFromLanguage(productStyle.style_profiles, locale)
    : null

  const stylePermalink =
    styleProfileIndex != null && styleProfileIndex > -1
      ? productStyle.style_profiles[styleProfileIndex].meta_information
          .permalink
      : null
  const styleSlug = t('style-slug')

  // ====== PRODUCT COLLECTION ========
  const productCollection = productData.collection ?? null
  const collectionProfileIndex = productCollection
    ? getProfileIndexFromLanguage(productCollection.collection_profiles, locale)
    : null

  const collectionPermalink =
    collectionProfileIndex != null && collectionProfileIndex > -1
      ? productCollection.collection_profiles[collectionProfileIndex]
          .meta_information.permalink
      : null
  const collectionSlug = t('collection-slug')

  //* I run a useEffect everytime the productPermalink changes
  //* then add the product to the recentlyViewedVar.
  //* But since I don't want to show the current product in the recently viewed carousel
  //* so I filter out all the products that are not that one
  //* plus I only want the first 15, so I make sure the index is lower than 15
  //* set a state variable to those products, which from the JSX will render.
  useEffect(() => {
    // ====== mutation to add the constructed item to the recentlyViewed field client side======
    addToRecentlyViewed({
      id: productData.id,
      name: productData.single_product.product_profile?.name,
      price: productData.single_product.price,
      image: productData.single_product.images[0]?.src,
      permalink: productPermalink,
      product_type: productData.type,
      dispatchInformation: productData.dispatch_information,
      discount: productData.discount,
    })
  }, [productPermalink, productData])

  const contentBlocks = productData.content_blocks

  return (
    <div>
      <TopProductPage
        productPermalink={productPermalink}
        product={productData}
        isSafari={isSafari}
      />

      {contentBlocks && (
        <Box sx={{ margin: '50px 0' }}>
          {contentBlocks.map((contentBlock, i) => (
            <ContentBlock key={i} data={contentBlock} index={i} />
          ))}
        </Box>
      )}

      {/* //TODO: there will be a component for manufacturer the will have a map on the right half with manufacturer coordinates and text on the left half */}

      {/* COLLECTION SECTION - ALIGNED Left */}

      {!b2bShowOnlySelectedProducts &&
        productCollection &&
        collectionProfileIndex !== null &&
        collectionProfileIndex !== undefined && (
          <CustomSection
            alignImage="left"
            locale={locale}
            product_containers={productCollection.product_containers}
            sectionName={
              productCollection.collection_profiles[collectionProfileIndex].name
            }
            sectionDescription={
              productCollection.collection_profiles[collectionProfileIndex]
                .description
            }
            sectionImage={productCollection.main_image?.src}
            dynamic={true} // Makes use of Dynamic product card instead of static
            permalink={collectionPermalink}
            slug={collectionSlug}
          />
        )}

      {/* STYLE SECTION - ALIGNED RIGHT */}

      {!b2bShowOnlySelectedProducts &&
        productStyle &&
        styleProfileIndex !== null &&
        styleProfileIndex !== undefined && (
          <CustomSection
            alignImage="right"
            locale={locale}
            product_containers={productStyle.product_containers}
            sectionName={productStyle.style_profiles[styleProfileIndex].name}
            sectionDescription={
              productStyle.style_profiles[styleProfileIndex].description
            }
            sectionImage={productStyle.main_image?.src}
            dynamic={true} // Makes use of Dynamic product card instead of static
            permalink={stylePermalink}
            slug={styleSlug}
          />
        )}

      {/* <Section fullWidth disablePadding>
        <Contact />
      </Section> */}

      {!b2bShowOnlySelectedProducts && sameCategoryProducts.length > 1 && (
        <ProductSlider
          product={productData}
          sliderTitle={moreProductsString}
          items={sameCategoryProducts}
          variant="h5"
        />
      )}

      {!b2bShowOnlySelectedProducts && recentlyViewed.length > 1 && (
        <ProductSlider
          product={productData}
          sliderTitle={recentlyViewedString}
          items={modifiedRecentlyViewed}
          variant="h5"
        />
      )}
    </div>
  )
}
