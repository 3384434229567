import { useQuery } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { imageFallback } from '../../../../../constants/Images'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },

      cardSelected: {
        position: 'relative',
        border: `5px solid ${theme.palette.secondary.main}`,
      },

      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "baseline",
        padding: theme.spacing(1, 0),
        // backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        filter: 'brightness(0.97) invert(0.001)',
        backgroundColor: '#fefefe',
      },
      fabricsMedia: {
        height: '6rem',
        backgroundSize: 'fit',
        [theme.breakpoints.down('lg')]: {
          height: '6rem',
        },
        [theme.breakpoints.down('md')]: {
          height: '6rem',
        },
      },
      productName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        marginBottom: 0,
        // textAlign: "center",
      },
      fabricCode: {
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        fontSize: '0.7rem',
        marginTop: '-8px',
        marginBottom: '-8px',
        color: theme.palette.text.secondary,
      },
      color: {
        height: '16px',
        width: '16px',
        display: 'inline-block',
        borderRadius: '50%',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      selected: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
      },

      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      badgeBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
      },

      tooltip: {
        fontSize: theme.typography.body1.fontSize,
      },
    }),
  { name: 'MuiFabricDrawerCardComponent' },
)

export default function FabricDrawerCard({
  fabricColor = null,
  fabricGroup = null,
  selected = false,
  onClick,
}) {
  const classes = useStyles() as any

  return (
    <Card
      elevation={0}
      square={true}
      className={selected ? classes.cardSelected : classes.card}
      onClick={() => {
        onClick(fabricColor, fabricGroup)
      }}
    >
      <div className={classes.cardActionAreaContainer}>
        <CardActionArea>
          <CardMedia
            className={classes.fabricsMedia}
            image={fabricColor.image?.src_thumbnail ?? imageFallback}
            sx={{ objectFit: 'cover' }}
          />
          {selected && (
            <CheckCircleIcon color="success" className={classes.selected} />
          )}
        </CardActionArea>
      </div>
      <CardContent className={classes.cardContent}>
        <Tooltip
          title={fabricColor.color_name}
          classes={{ tooltip: classes.tooltip }}
          placement="top"
        >
          <span className="cursorPointer">
            {fabricColor.color_name ? (
              <>
                <Typography
                  variant="body1"
                  // component="h5"
                  className={classes.fabricCode}
                >
                  {fabricColor.code}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  // component="h5"
                  className={classes.productName}
                >
                  {fabricColor.color_name}
                </Typography>
              </>
            ) : (
              <Typography
                gutterBottom
                variant="body1"
                // component="h5"
                className={classes.productName}
              >
                {fabricColor.code}
              </Typography>
            )}
          </span>
        </Tooltip>
      </CardContent>
    </Card>
  )
}
