import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { parse } from 'next-useragent'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import {
  imageFallback,
  imageLoadingPlaceholder,
} from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../operations/clientMutations/wishlistClient'
import { GET_PRODUCT_CONTAINER_ADDITIONAL_DATA } from '../../../operations/queries'
import {
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import { checkIfProductIsNew } from '../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import { dispatchInformationDate } from '../../../utilityFunctions/dispatchInformationDate'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import DiscountBadge from '../Utils/DiscountBadge'
import FastDeliveryBadge from '../Utils/FastDeliveryBadge'
import NewProductBadge from '../Utils/NewProductBadge'
import LayersIcon from '@mui/icons-material/Layers'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { ProductPrice } from '../../UtilityComponents/price'
import ProductCardPrice from './ProductCardPrice'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
      },
      rootInCarousel: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },

      cardActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        [theme.breakpoints.down('lg')]: {
          // padding: theme.spacing(1, 0),
        },
      },
      cardActionAreaContainer: {
        position: 'relative',
        height: '32rem',
        [theme.breakpoints.between('xs', 649)]: {
          height: '14rem',
        },
        [theme.breakpoints.between(650, 'lg')]: {
          height: '20rem',
        },
        [theme.breakpoints.between('lg', 1500)]: {
          height: '23rem',
        },
      },
      wishlistIconContainer: {
        position: 'absolute',
        padding: theme.spacing(2),
        right: 0,
        top: 0,
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1),
        },
      },
      cardActionsButtons: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      media: {
        height: '28rem',
        backgroundSize: 'contain',
        [theme.breakpoints.between('xs', 649)]: {
          height: '14rem',
        },
        [theme.breakpoints.between(650, 'lg')]: {
          height: '20rem',
        },
        [theme.breakpoints.between('lg', 1500)]: {
          height: '23rem',
        },
      },
      colorsContainer: {
        display: 'flex',
      },
      colorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        padding: theme.spacing(0.6, 0.5),
        cursor: 'pointer',
      },
      colorActive: {
        // borderBottom: "2px solid black",
        '&::after': {
          content: "''",
          display: 'block',
          width: '8px',
          height: '2px',
          margin: '4px auto 0px',
          backgroundColor: 'black',
          transition: 'background-color 0.3s ease 0s',
        },
      },
      color: {
        height: '16px',
        width: '16px',
        display: 'inline-block',
        borderRadius: '50%',
      },
      icon: {
        fontSize: '1.3rem',
        marginLeft: '1.3rem',
      },
      favoriteIcon: {
        color: 'rgb(241, 88, 86)',
        fontSize: '1.3rem',
      },

      imageAnchorTagWrapper: {
        height: '100%',
        position: 'relative',
        display: 'block',
      },

      badgeBox: {
        position: 'absolute',
        top: 5,
        left: 0,
        zIndex: 2,
      },

      advancedInfoBox: {
        position: 'absolute',
        bottom: 5,
        left: 0,
        zIndex: 2,
      },
    }),

  { name: 'MuiCategoryProductCardComponent' },
)

const CategoryProductCard = ({
  productContainer,
  locale,
  inCarousel = false,
}) => {
  const showPrice =
    process.env.NEXT_PUBLIC_SHOW_PRICE_IN_PRODUCT_LIST === 'true' ||
    !process.env.NEXT_PUBLIC_SHOW_PRICE_IN_PRODUCT_LIST

  if (productContainer.type === 'SIMPLE_PRODUCT') {
    return (
      <CategorySimpleProductCard
        productContainer={productContainer}
        locale={locale}
        inCarousel={inCarousel}
        showPrice={showPrice}
      />
    )
  } else if (productContainer.type === 'ADVANCED_PRODUCT') {
    return (
      <CategoryAdvancedProductCard
        productContainer={productContainer}
        locale={locale}
        inCarousel={inCarousel}
        showPrice={showPrice}
      />
    )
  } else {
    return <p>unhandled product card...</p>
  }
}

const CategorySimpleProductCard = ({
  productContainer,
  locale,
  inCarousel = false,
  showPrice = true,
}) => {
  const productProfileIndex: number = getProfileIndexFromLanguage(
    productContainer.single_product.product_profiles,
    locale,
  )

  // TODO ===> Think about this. This is good to keep data up to date, when using getStaticProps, but we need to keep an eye to monitor resource consumption and speed impact
  const { loading: loadingAdditional, data: additionalData } = useQuery(
    GET_PRODUCT_CONTAINER_ADDITIONAL_DATA,
    {
      variables: { productContainerID: productContainer.id, language: locale },
    },
  )

  const classes = useStyles() as any
  const [isInWishlist, setisInWishlist] = useState(false)
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  const [activeProduct, setActiveProduct] = useState({
    id: productContainer.id,
    price: productContainer.single_product.price,
    productProfile:
      productContainer.single_product.product_profiles[productProfileIndex],
    images: productContainer.single_product.images,
    createdAt: productContainer.createdAt,
    manufacturer: productContainer.manufacturer,
    dispatch_information: productContainer.dispatch_information,
    discount: productContainer.discount,
  })

  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')

  // === SET Additional dinamycally loaded product data
  useEffect(() => {
    if (additionalData && additionalData.findManyProductContainer.length > 0) {
      setActiveProduct({
        id: productContainer.id,
        price: productContainer.single_product.price,
        productProfile:
          productContainer.single_product.product_profiles[productProfileIndex],
        images: productContainer.single_product.images,
        createdAt: productContainer.createdAt,
        manufacturer: additionalData.findManyProductContainer[0].manufacturer,
        dispatch_information:
          additionalData.findManyProductContainer[0].dispatch_information,
        discount: productContainer.discount,
      })
    }
  }, [additionalData])

  // ======== get the wishlist items from the reactive var at first render ========
  useEffect(() => {
    wishlistItems.forEach((wishlistItem) => {
      if (wishlistItem.id === productContainer.id) {
        setisInWishlist(true)
      }
    })

    return () => setisInWishlist(false)
  }, [wishlistItems])

  //! This approach is no longer used, as we shifted to getStaticProps (if switching back to getServerSide props, we should turn this on)
  // ===== LAZY QUERY FOR GROUPED PRODUCTS
  // const [groupedByColorProducts, setGroupedByColorProducts] = useState([])
  // const [
  //   fetchGroupedProducts,
  //   { data: dynamicGroupedProductsData, loading, error, called, fetchMore },
  // ] = useLazyQuery(GET_PRODUCT_CONTAINER_GROUPED_BY_COLOR);

  // // console.log("We need -->", productContainer.single_product.grouped_by_color);
  // const gorupedProductContainerIds =
  //   productContainer.single_product.grouped_by_color.map((item) => {
  //     return item.product_containerId;
  //   });

  // useEffect(() => {
  //   if (productContainer.single_product.grouped_by_color.length > 0) {
  //     const gorupedProductContainerIds =
  //       productContainer.single_product.grouped_by_color.map((item) => {
  //         return item.product_containerId;
  //       });

  //     fetchGroupedProducts({
  //       variables: {
  //         productContainerIds: gorupedProductContainerIds,
  //       },
  //     });
  //   }
  // }, []);
  // console.log("gorupedProductContainerIds :>> ", gorupedProductContainerIds);

  // useEffect(() => {
  //   if (dynamicGroupedProductsData) {
  //     //-- We have grouped products..
  //     // console.log("We have grouped products in this card");
  //     // console.log("dynamicGroupedProductsData ->", dynamicGroupedProductsData);
  //     const groupedByColorProducts = [];

  //     //check if any of these products has a profile matching the current locale
  //     //if yes, push that product to the groupedByColorProducts array
  //     for (let groupedByColorProduct of dynamicGroupedProductsData.productContainers) {
  //       const productProfileIndex: number = getProfileIndexFromLanguage(
  //         groupedByColorProduct.single_product.product_profiles,
  //         locale
  //       );

  //       if (
  //         groupedByColorProduct.single_product.product_profiles[
  //           productProfileIndex
  //         ]
  //       ) {
  //         groupedByColorProducts.push({
  //           ...groupedByColorProduct,
  //           productProfile:
  //             groupedByColorProduct.single_product.product_profiles[
  //               productProfileIndex
  //             ],
  //         });
  //       }
  //     }
  //     setGroupedByColorProducts(groupedByColorProducts);
  //   }
  // }, [dynamicGroupedProductsData]);
  //! ^^^^ (above) This approach is no longer used, as we shifted to getStaticProps (if switching back to getServerSide props, we should turn this on)

  // === GETSTATICPROPS Slution --- check if any of these products has a profile matching the current locale

  let groupedByColorProducts = [] as any

  //if yes, push that product to the groupedByColorProducts array
  for (let groupedByColorProduct of productContainer.single_product
    .grouped_by_color) {
    const productProfileIndex: number = getProfileIndexFromLanguage(
      groupedByColorProduct.product_profiles,
      locale,
    )

    if (groupedByColorProduct.product_profiles[productProfileIndex]) {
      groupedByColorProducts.push({
        ...groupedByColorProduct,
        productProfile:
          groupedByColorProduct.product_profiles[productProfileIndex],
      })
    }
  }

  // Order images by display order
  // Convert to list
  const productImages = activeProduct.images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

  // change src for SAFARI as it does not support webp...
  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  const userAgent = appContext ? appContext.userAgent : null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false

  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  const mainProductImage = isSafari
    ? productImages[0]?.src ?? imageFallback
    : productImages[0]?.src_xs ??
      productImages[0]?.src_md ??
      productImages[0]?.src ??
      imageFallback

  // =============================================
  // ================ BADGES =====================
  //? =============================================
  // ============ DISCOUNTS ======================
  const { discounted, discountedPrice } = checkDiscountValidity(
    activeProduct.discount,
    activeProduct.price,
  )
  const discountPercentage = activeProduct.discount
    ? '-' + activeProduct.discount.discount + '%'
    : 0

  // ========== DISPATCH INFORMATION ===============
  // ==== We get shop settings data from APP Context
  // === We need to get dispatch data from SHOP Settings
  const shopSettings = appContext ? appContext.shopSettings : null
  const defaultDispatchInformation = appContext
    ? appContext.shopSettings.default_dispatch_information
    : null
  // === We need to get dispatch data from MANUFACTURER
  const manufacturerDefaultDispatch = activeProduct.manufacturer
    ? activeProduct.manufacturer.default_dispatch_information
    : null

  const dispatchTime = dispatchInformationDate(
    activeProduct.dispatch_information,
    defaultDispatchInformation,
    manufacturerDefaultDispatch,
  )
  // We turn on FastDelivery Badge when dispatch time is less than 10 days.
  const fastDelivery = dispatchTime <= 10 ? true : false
  // ============ NEW BADGE ========================
  const newProduct = checkIfProductIsNew(activeProduct.createdAt)

  if (activeProduct.productProfile) {
    return (
      <Card
        elevation={0}
        square={true}
        className={clsx(inCarousel ? classes.rootInCarousel : classes.root)}
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <div className={classes.badgeBox}>
          {discounted && <DiscountBadge content={discountPercentage} />}
          {fastDelivery && <FastDeliveryBadge />}
          {newProduct && <NewProductBadge />}
        </div>

        <div className={classes.cardActionAreaContainer}>
          <CardActionArea style={{ height: '100%' }}>
            {/*  // TODO ----> We should include lazy loading in this new component. */}
            <Link
              href={`/${productSlug}/${activeProduct.productProfile.meta_information.permalink}`}
              prefetch={false}
            >
              <a className={classes.imageAnchorTagWrapper}>
                <Image
                  layout="fill"
                  placeholder="blur"
                  blurDataURL={imageLoadingPlaceholder}
                  objectFit="contain"
                  src={mainProductImage}
                  alt={
                    productContainer.single_product.product_profiles[
                      productProfileIndex
                    ]?.name
                  }
                  priority={true}
                />
              </a>
            </Link>
          </CardActionArea>
          <div className={classes.wishlistIconContainer}>
            {!isInWishlist ? (
              <FavoriteBorderIcon
                className={classes.icon}
                aria-label="add to favorites"
                onClick={() => {
                  addToWishlist({
                    id: productContainer.id,
                    name: productContainer.single_product.product_profiles[
                      productProfileIndex
                    ]?.name,
                    price: productContainer.single_product.price,
                    sku: productContainer.single_product.sku,
                    image: productContainer.single_product.images[0].src,
                    permalink:
                      productContainer.single_product.product_profiles[
                        productProfileIndex
                      ].meta_information.permalink,
                    product_type: productContainer.type,
                    dispatchInformation: productContainer.dispatch_information,
                    discount: productContainer.discount,
                  })
                  wishlistDrawerVar(true)
                  setisInWishlist(true)
                }}
              />
            ) : (
              <FavoriteIcon
                className={classes.favoriteIcon}
                aria-label="remove from favorites"
                onClick={() => {
                  removeFromWishlist(productContainer.id)
                  setisInWishlist(false)
                }}
              />
            )}
          </div>
        </div>
        <ProductCardPrice
          price={activeProduct.price}
          discount={activeProduct.discount}
          link={`/${productSlug}/${activeProduct.productProfile.meta_information.permalink}`}
          productName={activeProduct.productProfile.name}
        >
          <CardActions disableSpacing className={classes.cardActions}>
            {/* //* =========== SHOW COLORS IF THE ARRAY OF GROUPED_BY_COLOR IS NOT EMPTY*/}
            {groupedByColorProducts.length !== 0 && (
              <div className={classes.colorsContainer}>
                <div
                  className={clsx(
                    activeProduct.id === productContainer.id &&
                      classes.colorActive,
                    classes.colorContainer,
                  )}
                >
                  <span
                    className={classes.color}
                    style={{
                      background: `${
                        productContainer.single_product.main_color
                          ?.background ??
                        productContainer.single_product.main_color?.hex
                      }`,
                    }}
                  />
                </div>
                {groupedByColorProducts.map((groupedProduct) => (
                  <Link
                    href={`/${productSlug}/${groupedProduct.productProfile.meta_information.permalink}`}
                    key={groupedProduct.id}
                    prefetch={false}
                  >
                    <div
                      className={clsx(
                        activeProduct.id === groupedProduct.id &&
                          classes.colorActive,
                        classes.colorContainer,
                      )}
                      key={groupedProduct.id}
                      onMouseEnter={() => {
                        setActiveProduct({
                          id: groupedProduct.id,
                          price: groupedProduct.price,
                          productProfile: groupedProduct.productProfile,
                          images: groupedProduct.images,
                          createdAt: groupedProduct.createdAt,
                          manufacturer: groupedProduct.manufacturer,
                          discount: groupedProduct.discount,
                          dispatch_information:
                            groupedProduct.dispatch_information,
                        })
                      }}
                      onMouseLeave={() =>
                        setActiveProduct({
                          id: productContainer.id,
                          createdAt: productContainer.createdAt,
                          price: productContainer.single_product.price,
                          productProfile:
                            productContainer.single_product.product_profiles[
                              productProfileIndex
                            ],
                          images: productContainer.single_product.images,
                          manufacturer: productContainer.manufacturer,
                          discount: productContainer.discount,
                          dispatch_information:
                            productContainer.dispatch_information,
                        })
                      }
                    >
                      <span
                        className={classes.color}
                        style={{
                          background: `${
                            groupedProduct.main_color?.background ??
                            groupedProduct.main_color?.hex
                          }`,
                        }}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            )}

            {/* //* ========== SHOW SIZE IF GROUPED_BY_SIZE ARRAY IS NOT EMPTY AND THE PRODUCT DOESNT HAVE COLOR VARIATIONS */}
            {productContainer.single_product.grouped_by_size.length !== 0 &&
              groupedByColorProducts.length === 0 && (
                <Typography component="p" variant="body1">
                  {
                    productContainer.single_product.product_profiles[
                      productProfileIndex
                    ]?.size_name
                  }
                </Typography>
              )}
          </CardActions>
        </ProductCardPrice>
      </Card>
    )
  } else {
    return <p>no active</p>
  }
}

const CategoryAdvancedProductCard = ({
  productContainer,
  locale,
  inCarousel = false,
  showPrice = true,
}) => {
  const productProfileIndex: number = getProfileIndexFromLanguage(
    productContainer.advanced_product?.advanced_product_profiles,
    locale,
  )

  const classes = useStyles() as any
  const [isInWishlist, setisInWishlist] = useState(false)
  const wishlistItems = useReactiveVar(wishlistItemsVar)

  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const configurableProductText = t('configurable-product')

  // ======== get the wishlist items from the reactive var at first render ========
  useEffect(() => {
    wishlistItems.forEach((wishlistItem) => {
      if (wishlistItem.id === productContainer.id) {
        setisInWishlist(true)
      }
    })
    return () => setisInWishlist(false)
  }, [wishlistItems])

  // Order images by display order
  // Convert to list
  const productImages = productContainer.advanced_product?.images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

  // change src for SAFARI as it does not support webp...

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  const userAgent = appContext ? appContext.userAgent : null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false
  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )
  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  const mainProductImage = isSafari
    ? productImages[0]?.src ?? imageFallback
    : productImages[0]?.src_xs ??
      productImages[0]?.src_md ??
      productImages[0]?.src ??
      imageFallback

  // =============================================
  // ================ BADGES =====================
  //? =============================================
  // ============ DISCOUNTS ======================
  const { discounted, discountedPrice } = checkDiscountValidity(
    productContainer.discount,
    productContainer.advanced_product.price_from,
  )
  const discountPercentage = productContainer.discount
    ? '-' + productContainer.discount.discount + '%'
    : 0

  // ========== DISPATCH INFORMATION ===============
  // ==== We get shop settings data from APP Context
  // === We need to get dispatch data from SHOP Settings
  const shopSettings = appContext ? appContext.shopSettings : null
  const defaultDispatchInformation = appContext
    ? appContext.shopSettings.default_dispatch_information
    : null
  // === We need to get dispatch data from MANUFACTURER
  const manufacturerDefaultDispatch = productContainer.manufacturer
    ? productContainer.manufacturer.default_dispatch_information
    : null

  const dispatchTime = dispatchInformationDate(
    productContainer.dispatch_information,
    defaultDispatchInformation,
    manufacturerDefaultDispatch,
  )
  // We turn on FastDelivery Badge when dispatch time is less than 10 days.
  const fastDelivery = dispatchTime <= 10 ? true : false
  // ============ NEW BADGE ========================
  const newProduct = checkIfProductIsNew(productContainer.createdAt)

  if (productProfileIndex !== null) {
    return (
      <Card
        elevation={0}
        square={true}
        className={clsx(inCarousel ? classes.rootInCarousel : classes.root)}
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <div className={classes.badgeBox}>
          {discounted && <DiscountBadge content={discountPercentage} />}
          {fastDelivery && <FastDeliveryBadge />}
          {newProduct && <NewProductBadge />}
        </div>

        <div className={classes.cardActionAreaContainer}>
          <CardActionArea style={{ height: '100%' }}>
            {/*  // TODO ----> We should include lazy loading in this new component. */}
            <Link
              href={`/${productSlug}/${productContainer.advanced_product.advanced_product_profiles[productProfileIndex]?.meta_information.permalink}`}
              prefetch={false}
            >
              <a className={classes.imageAnchorTagWrapper}>
                <Image
                  layout="fill"
                  placeholder="blur"
                  blurDataURL={imageLoadingPlaceholder}
                  objectFit="contain"
                  src={mainProductImage}
                  alt={
                    productContainer.advanced_product.advanced_product_profiles[
                      productProfileIndex
                    ]?.name
                  }
                  priority={true}
                />
              </a>
            </Link>

            <div className={classes.advancedInfoBox}>
              <Tooltip title={configurableProductText} placement="top">
                <LayersOutlinedIcon sx={{ fontSize: 30 }} />
              </Tooltip>
            </div>
          </CardActionArea>
          <div className={classes.wishlistIconContainer}>
            {!isInWishlist ? (
              <FavoriteBorderIcon
                className={classes.icon}
                aria-label="add to favorites"
                onClick={() => {
                  addToWishlist({
                    id: productContainer.id,
                    name: productContainer.advanced_product
                      .advanced_product_profiles[productProfileIndex]?.name,
                    price: productContainer.advanced_product.price_from,
                    sku: productContainer.advanced_product.sku,
                    image: productContainer.advanced_product?.images[0].src,
                    permalink:
                      productContainer.advanced_product
                        .advanced_product_profiles[productProfileIndex]
                        .meta_information.permalink,
                    product_type: productContainer.type,
                    dispatchInformation: productContainer.dispatch_information,
                    discount: productContainer.discount,
                  })
                  wishlistDrawerVar(true)
                  setisInWishlist(true)
                }}
              />
            ) : (
              <FavoriteIcon
                className={classes.favoriteIcon}
                aria-label="remove from favorites"
                onClick={() => {
                  removeFromWishlist(productContainer.id)
                  setisInWishlist(false)
                }}
              />
            )}
          </div>
        </div>
        <ProductCardPrice
          price={productContainer.advanced_product.price_from}
          discount={0}
          link={`/${productSlug}/${productContainer.advanced_product.advanced_product_profiles[productProfileIndex]?.meta_information.permalink}`}
          productName={
            productContainer.advanced_product.advanced_product_profiles[
              productProfileIndex
            ]?.name
          }
        />
      </Card>
    )
  } else {
    return <p>Not translated</p>
  }
}

export default CategoryProductCard
