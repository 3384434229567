import { useReactiveVar } from '@apollo/client'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../context/appContext'
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../operations/clientMutations/wishlistClient'
import {
  sofaModulesDrawerStateVar,
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import { checkIfProductIsNew } from '../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import {
  dispatchInformationDate,
  dispatchInformationDateExtended,
} from '../../../utilityFunctions/dispatchInformationDate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useSnackbarContext } from '../Utils'
import DeliveryInformationBox from '../Utils/DeliveryInformationBox'
import DiscountBadge from '../Utils/DiscountBadge'
import FastDeliveryBadge from '../Utils/FastDeliveryBadge'
import NewProductBadge from '../Utils/NewProductBadge'
import ReturnsInformationBox from '../Utils/ReturnsInformationBox'
import ShippingInformationBox from '../Utils/ShippingInformationBox'
import VariantsDrawer from './VariantsDrawer'
import CloseIcon from '@mui/icons-material/Close'
import SofaVideoInstructions from './AdvancedProductElements/SofaVideoInstructions'
import CountryOfOriginBox from '../Utils/CountryOfOriginBox'
import { ProductPrice } from '../../UtilityComponents/price'
import { TrackingModuleButton } from '../Utils/TrackingModuleComponent'
import { TENANT_KAUNO_BALDAI } from '../../../constants/featureFlags'
import InfoLinkBox from '../Utils/InfoLinkBox'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      section: {
        zIndex: 2,
        padding: theme.spacing(3, 2),
        height: 'fit-content',
        [theme.breakpoints.up(1440)]: {
          position: 'sticky',
          top: '8.5rem',
        },
        [theme.breakpoints.between(1175, 1440)]: {
          position: 'sticky',
          top: 'auto',
          bottom: 0,
          marginTop: 0,
        },
      },
      productNameContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: theme.spacing(2),
        // alignItems: "center",
        // marginBottom: "1rem",
      },
      productName: {
        [theme.breakpoints.down('lg')]: {
          fontSize: '1.5rem',
        },
      },
      row: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        marginBottom: theme.spacing(4),
        backgroundColor: '#e7edf0',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(4),
        },
      },
      colorsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      colorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        padding: theme.spacing(0.6, 0),
        cursor: 'pointer',
        marginRight: theme.spacing(1.5),
      },
      colorActive: {
        '&::after': {
          content: "''",
          display: 'block',
          width: '8px',
          height: '2px',
          margin: '4px auto 0px',
          backgroundColor: 'black',
          transition: 'background-color 0.3s ease 0s',
        },
      },
      color: {
        height: '20px',
        width: '20px',
        display: 'inline-block',
        borderRadius: '50%',
        transition: 'all 0.2s ease-in-out',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      shortDescriptionContainer: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(2),
        },
      },

      quantityContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
      },
      quantityText: {
        // color: theme.palette.secondary.light,
        color: '#f44336',
      },

      priceContainer: {
        display: 'flex',
        marginBottom: '1rem',
      },

      drawer: {
        width: '450px',
      },
      topDrawerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        padding: '1rem',
      },
      accordion: {
        marginBottom: theme.spacing(2),
        border: 0,
        backgroundColor: theme.palette.background.default,
        '& .MuiAccordionSummary-root': {
          padding: 0,
        },
      },
      wishlistIcon: {
        justifySelf: 'end',
      },
      wishlistIconFilled: {
        justifySelf: 'end',
        color: 'rgb(241, 88, 86)',
      },
      addToCartCaption: {
        marginTop: theme.spacing(4),
        display: 'block',
        opacity: '0.7',
      },
      addToCartButtonContainer: {
        // [theme.breakpoints.down("md")]: {
        //   background: theme.palette.background.default,
        //   paddingBottom: "1rem",
        //   position: "sticky",
        //   bottom: 0,
        // },
      },

      instructionsButtonContainer: {
        marginTop: '10px',
      },
      mobileDimensionsAccordion: {
        marginBottom: theme.spacing(2),
        border: 0,
        backgroundColor: theme.palette.background.default,
        '& .MuiAccordionSummary-root': {
          padding: 0,
        },
        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      },
      mobileAccordionSummary: {
        display: 'flex',
        flexDirection: 'column',
      },
      chevronRight: {
        cursor: 'pointer',
      },
      rowContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      pricesContainer: {
        display: 'flex',
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        fontWeight: 400,
        color: theme.palette.text.secondary,
        marginLeft: '10px',
      },

      badgeBox: {
        position: 'absolute',
        top: 0,
        left: '-78px',
        zIndex: 20,
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      priceFrom: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        // color: theme.palette.text.secondary,
        // fontWeight: 400,
        // textDecoration: 'underline',
      },
    }),
  { name: 'MuiProductPanelComponent' },
)

const colors = ['#f9c901', '#333', '#E49A53']

export default function AdvancedProductPanel({
  product,
  productPermalink,
  className = null,
}) {
  const { t, i18n } = useTranslation(['common'])
  const configureButtonText = t('configure-button')
  const instructionsButtonText = t('instructions-text')
  const priceFromText = t('price-from-text')

  //---- Modal Controls
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const classes = useStyles() as any
  const [isInWishlist, setIsInWishlist] = useState(false)
  // const variantsDrawerState = useReactiveVar(variantsDrawerStateVar)
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  const { priceListId } = useAppContext()

  // const containerID = product?.advanced_product?.product_container?.id ?? null

  const [discounted, setDiscounted] = useState(null)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discountPercentage, setDiscountPercentage] = useState(null)

  // ===== PRICE

  const [productPrice, setProductPrice] = useState(
    product.advanced_product.base_prices?.find(
      (p) => p.price_listId === priceListId,
    )?.price_from,
  )

  // ======== get the wishlist items from the cache at first render ========
  useEffect(() => {
    wishlistItems.forEach((wishlistItem) => {
      if (wishlistItem.id === product.id) {
        setIsInWishlist(true)
      }
    })

    return () => setIsInWishlist(false)
  }, [wishlistItems])

  // =============================================
  // ================ BADGES =====================
  //? =============================================
  // ============ DISCOUNTS ======================
  useEffect(() => {
    const { discounted, discountedPrice } = checkDiscountValidity(
      product.discount,
      productPrice,
    )
    const discountPercentage = product.discount
      ? '-' + product.discount.discount + '%'
      : 0
    setDiscounted(discounted)
    setDiscountedPrice(discountedPrice)
    setDiscountPercentage(discountPercentage)
  }, [product])

  // ========== DISPATCH INFORMATION ===============
  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  // === We need to get dispatch data from SHOP Settings
  const shopSettings = appContext ? appContext.shopSettings : null
  const defaultDispatchInformation = appContext
    ? appContext.shopSettings.default_dispatch_information
    : null
  // === We need to get dispatch data from MANUFACTURER
  const manufacturerDefaultDispatch = product.manufacturer
    ? product.manufacturer.default_dispatch_information
    : null

  const dispatchTime = dispatchInformationDate(
    product.dispatch_information,
    defaultDispatchInformation,
    manufacturerDefaultDispatch,
  )

  const { total_delivery_time, manufacturing_time, shipping_time } =
    dispatchInformationDateExtended(
      product.dispatch_information,
      defaultDispatchInformation,
      manufacturerDefaultDispatch,
    )

  const pricesDisabled = appContext.pricesDisabled

  // console.log(" shipping_time ->", total_delivery_time);
  // console.log(" manufacturing_time ->", manufacturing_time);
  // console.log(" shipping_time ->", shipping_time);

  // We turn on FastDelivery Badge when dispatch time is less than 10 days.
  const fastDelivery = dispatchTime <= 10 ? true : false
  // ============ NEW BADGE ========================
  const newProduct = checkIfProductIsNew(product.createdAt)

  // ============ RETURNS POLICIES ========================
  const standard_returns_policy_profiles = appContext
    ? appContext.shopSettings.standard_returns_policy_profiles
    : null
  const configurable_product_returns_policy_profiles = appContext
    ? appContext.shopSettings.configurable_product_returns_policy_profiles
    : null
  // If product has managed stock then standart ese configurable policy
  const returnsPolicy = product.advanced_product.follow_product_stock
    ? standard_returns_policy_profiles
    : configurable_product_returns_policy_profiles

  // ============ SHIPPING METHOD ========================
  // A shipping method can be set on product level
  // on category level
  // on manufacturer level
  // on shop setttings level
  // Currently we will only default shop settings method.
  // Also Filter - is enabled??
  // TODO query all others and apply dispatchInformationDate to pick correct one

  const shipping_method_in_appContext = appContext
    ? appContext.shopSettings.default_shipping_method
    : null

  const shipping_methods_in_advanced_product = product?.advanced_product
    ?.shipping_methods
    ? JSON.parse(
        JSON.stringify(product.advanced_product.shipping_methods),
      ).sort((a, b) => a.price_for_box - b.price_for_box)
    : null

  const shipping_method_filtered = shipping_methods_in_advanced_product[0]
  const shipping_method =
    shipping_method_filtered ?? shipping_method_in_appContext

  //--- Additional informational tabs
  const countryOfOrigin = product?.advanced_product?.country_of_origin ?? null
  const D3FileLink =
    product?.advanced_product?.product_details?.d3_model ?? null
  const assemblyInstructionsLink =
    product?.advanced_product?.product_details?.assembly_instructions ?? null

  const productProfile = product?.advanced_product?.advanced_product_profile
  return (
    <>
      <section className={clsx(classes.section, className && className)}>
        {/* PRODUCT NAME CONTAINER */}
        <div className={classes.productNameContainer}>
          <Typography
            variant="h5"
            component="h1"
            className={classes.productName}
          >
            {productProfile.name}
          </Typography>
        </div>

        {/* Long product name */}
        <div className={classes.shortDescriptionContainer}>
          <Typography variant="body1">
            {productProfile.long_product_name}
          </Typography>
        </div>

        {/* BADGES */}
        <div className={classes.badgeBox}>
          {discounted && <DiscountBadge content={discountPercentage} left />}
          {fastDelivery && <FastDeliveryBadge left />}
          {newProduct && <NewProductBadge left />}
        </div>

        {/* PRICE CONTAINER */}
        {!pricesDisabled && (
          <div className={classes.pricesContainer}>
            <Typography
              variant="body2"
              component="p"
              gutterBottom
              className={classes.priceFrom}
            >
              {`${priceFromText} `}
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
              {' '}
              {discountedPrice && <ProductPrice price={discountedPrice} />}
            </Typography>
            <Typography
              variant={discounted ? 'h6' : 'h6'}
              component="p"
              gutterBottom
              className={clsx(discounted && classes.discoutedPrice)}
            >
              <ProductPrice price={productPrice} />
            </Typography>
          </div>
        )}

        {/* DESCRIPTION CONTAINER */}
        {/* // TODO ====> Change to short description and move description inside photo slider */}
        <div className={classes.shortDescriptionContainer}>
          {productProfile?.description &&
            productProfile.description.split('\n').map((line, i) => {
              if (line) {
                return (
                  <Typography key={i} variant="body1">
                    <span dangerouslySetInnerHTML={{ __html: line }}></span>
                  </Typography>
                )
              } else {
                return <br key={i}></br>
              }
            })}
        </div>

        <div className={classes.addToCartButtonContainer}>
          <Button
            variant="contained"
            // disabled={productDisabled || loading}
            onClick={() => {
              const navBarHeight =
                (document.getElementById('header')?.offsetHeight ?? 0) +
                window.scrollY

              if (document.getElementById('fabricSelect')) {
                const scrollY =
                  document.getElementById('fabricSelect').offsetTop
                window.scrollBy(0, scrollY - navBarHeight)
                return
              }
              if (document.getElementById('fabricSelectDrawer')) {
                const scrollY =
                  document.getElementById('fabricSelectDrawer').offsetTop
                window.scrollBy(0, scrollY - navBarHeight)
                return
              }
              if (document.getElementById('configurator-header')) {
                const scrollY = document.getElementById(
                  'configurator-header',
                ).offsetTop
                window.scrollBy(0, scrollY - navBarHeight)
                return
              }
              if (document.getElementById('additionalComponentSelect')) {
                const scrollY = document.getElementById(
                  'additionalComponentSelect',
                ).offsetTop
                window.scrollBy(0, scrollY - navBarHeight)
                return
              }

              // console.log('Pressing configure...')
            }}
          >
            {configureButtonText}
          </Button>
        </div>

        <div className={classes.instructionsButtonContainer}>
          <Button
            variant="outlined"
            // disabled={productDisabled || loading}
            onClick={(e) => handleOpen()}
          >
            {instructionsButtonText}
          </Button>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              // width: '50%',
              bgcolor: 'background.paper',
              // border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              padding: 0,
              minWidth: 300,
              width: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                paddingTop: '50px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: '20px',
              }}
            >
              <IconButton
                aria-label="close"
                size="large"
                onClick={handleClose}
                // sx={{ position: 'absolute', right: '-40px', top: '-40px' }}
                sx={{ position: 'absolute', right: 0, top: 0 }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>

              <SofaVideoInstructions />
            </div>
          </Box>
        </Modal>

        {/* </div> */}

        {/* //* ============= DELIVERY INFORMATION SELECTION */}
        {process.env.NEXT_PUBLIC_TENANT !== TENANT_KAUNO_BALDAI && (
          <DeliveryInformationBox
            total_delivery_time={total_delivery_time}
            manufacturing_time={manufacturing_time}
            shipping_time={shipping_time}
            locale={appContext.locale}
          />
        )}
        {/* //* ============= SHIPPING INFORMATION SELECTION */}
        {process.env.NEXT_PUBLIC_TENANT !== TENANT_KAUNO_BALDAI && (
          <ShippingInformationBox
            shipping_method={shipping_method}
            locale={appContext.locale}
          />
        )}
        {/* //* ============= 3D Model SELECTION */}
        {D3FileLink && (
          <InfoLinkBox
            link={D3FileLink}
            title={t('3d-model')}
            icon={ThreeDRotationIcon}
          />
        )}

        {/* //* ============= ASSEMBLY INSTRUCTIONS DOWNLOAD SELECTION */}
        {assemblyInstructionsLink && (
          <InfoLinkBox
            link={assemblyInstructionsLink}
            title={t('assembly-instructions')}
          />
        )}

        {process.env.NEXT_PUBLIC_TENANT === TENANT_KAUNO_BALDAI && (
          <InfoLinkBox
            title={'Care Instructions'}
            link={
              'https://storage.googleapis.com/furnisystems-public/Home%20Science%20(Eng)%20Ch-9.pdf'
            }
          />
        )}

        {/* //* ============= RETURNS POLICY INFORMATION SELECTION */}
        {process.env.NEXT_PUBLIC_TENANT !== TENANT_KAUNO_BALDAI && (
          <ReturnsInformationBox
            returns_policy={returnsPolicy}
            locale={appContext.locale}
          />
        )}
        {/* //* ============= countryOfOrigin SELECTION */}
        {countryOfOrigin && (
          <CountryOfOriginBox
            countryCode={countryOfOrigin}
            locale={appContext.locale}
          />
        )}

        {/* //* ============= TRACKING MODULE SECTION */}
        <TrackingModuleButton
          type={'consultation-button'}
          variant="contained"
        />
      </section>
      {/* This Variants drawer is used for Policy preview here... (Need to fix this at some point) */}
      <VariantsDrawer
        groupedByColorProducts={[]}
        groupedBySizeProducts={[]}
        groupedByFrameColor={[]}
      />
    </>
  )
}
