import { Box, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'next-i18next'

const useStylesDetailsRowDimensions = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {},
      dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
      },
      property: {
        width: '50%',
      },
      value: {
        width: '50%',
        textAlign: 'center',
      },

      title: {
        // position: 'absolute',
        borderBottom: '1px solid #e2e1e0',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
        marginBottom: '12px',
      },
    }),
  { name: 'MuiSofaConfigurationDetailsDimensions' },
)

export const DimensionsBlockTitle = ({
  title,
  subtitle = null,
  children = null,
}) => {
  //---
  const classes = useStylesDetailsRowDimensions() as any
  const { t } = useTranslation(['common'])
  const yesString = t('yes')
  const noString = t('no')
  const dimensionsTitle = t('product-info-box-dimensions-title')
  const noProductInfoFallback = t('no-product-info-fallback')
  const widthString = t('product-width')
  const heightString = t('product-height')
  const lengthString = t('product-length')
  const seatHeightString = t('product-seat_height')
  const seatDepthString = t('product-seat_depth')
  const armsRestWidthString = t('product-arm-rest-width')
  const backRestWidthString = t('product-back-rest-width')
  const metricString = ' cm'
  const metricWeightString = ' kg'

  const weightString = t('product-weight')
  const seatWidthString = t('product-seat_width')
  //-- To translate
  const radiusString = t('product-radius')
  const armrestHeightString = t('product-armrest-height')
  const mattressWidthString = t('product-mattress-width')
  const mattressLengthString = t('product-mattress-length')
  const mattressHeightString = t('product-mattress-height')
  const headboardHeightString = t('product-headboard-height')
  const headboardWidthString = t('product-headboard-width')
  const tableExtendedLenghString = t('product-table-extended-lengh')
  const shadeHeightString = t('product-shade-height')
  const shadeRadiusString = t('product-shade-radius')
  const lyingPartHeightString = t('product-lying-part-height')
  const legHeightString = t('product-leg-height')
  const mattressOverlayHeightString = t('product-mattress-overlay-height')
  const bedboxHeightString = t('product-bedbox-height')
  const bedboxWidthString = t('product-bedbox-width')
  const bedboxLengthString = t('product-bedbox-length')
  const frameThicknessString = t('product-frame-thickness')
  const tableTopThicknessString = t('product-table-top-thickness')
  const tableLegWidthString = t('product-table-leg-width')
  const fabricUsageString = t('product-fabric-usage')
  const cornerPartLengthString = t('product-corner-part-length')

  let additionalRowStyles = {}
  let variantRow: 'body1' | 'body2' = 'body1'
  let variantValue: 'body2' | 'body1' = 'body2'

  return (
    <Box className={classes.title}>
      <Typography variant="h6" component="p" style={{ marginRight: '100px' }}>
        {title}
      </Typography>
      <div className={classes.pricesContainer}>{children}</div>
    </Box>
  )
}

export default DimensionsBlockTitle
