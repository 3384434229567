import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  Divider,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from '../../../operations/clientMutations/cartClient'
import { GET_PRODUCT_IN_CART } from '../../../operations/queries/headerQueries'
import { cartItemsVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import dynamic from 'next/dynamic'
import { parseSofaCombination } from '../../../utilityFunctions/parseSofaCombination'
// import SofaCombinationCartPreview from './SofaCombinationCartPreview'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { imageFallback } from '../../../constants/Images'
import FabricAndAdditionalComponentsCartPreview from './FabricAndAdditionalComponentsCartPreview'
import PreviewSelectedAdditionalComponent from '../ProductPageElements/AdvancedProductElements/Components/PreviewSelectedAdditionalComponent'
import { ProductPrice } from '../../UtilityComponents/price'
import { getComponentGroupName } from '../../../utilityFunctions/getComponentGroupName'

const SofaCombinationCartPreview = dynamic(
  () => import('./SofaCombinationCartPreview'),
  {
    ssr: false,
  },
)

const useStyles = makeStyles(
  (theme) => ({
    cartItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 1.5fr',
      // height: '10rem',
      padding: theme.spacing(0, 2),
      margin: '10px 0 ',
    },
    cartItem__imageWrapper: {
      padding: '0.5rem',
      cursor: 'pointer',
    },
    media: {
      height: '100%',
      backgroundSize: 'contain',
    },
    cartItem__info: {
      padding: '0.5rem 0 0.5rem 0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cartItem__actions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cartItem__quantitySelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '40%',
      padding: theme.spacing(0.5, 1),
      border: '1px solid #d3d3d3',
      [theme.breakpoints.only('xs')]: {
        width: '50%',
      },
    },
    icon: {
      cursor: 'pointer',
      fontSize: '1rem',
    },
    remove: {
      textDecoration: 'underline',
      cursor: 'pointer',
      // paddingRight: theme.spacing(2),
    },
    pricesContainer: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    discoutedPrice: {
      textDecoration: 'line-through',
    },
    skeleton: {},

    advancedPreviewBox: {
      // background: 'grey',
    },

    productName: {
      cursor: 'pointer',
      width: 'fit-content',
    },
  }),
  { name: 'MuiCartItemComponent' },
)

export default function CartItemComponent({ cartItem }) {
  if (cartItem.product_type === 'SIMPLE_PRODUCT') {
    return <CartItemComponentSingleProduct cartItem={cartItem} />
  } else if (cartItem.product_type === 'ADVANCED_PRODUCT') {
    return <CartItemComponentAdvancedProduct cartItem={cartItem} />
  } else {
    // unhandlend entry
    return <p>Unhandled product type</p>
  }
}

const CartItemComponentSingleProduct = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItem = t('remove-cart-item')
  const classes = useStyles() as any
  // Since cartItems won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()

  //* =================================================================================
  //* ================== lazy query to get latest cartItems info ======================
  //* =================================================================================
  const { data, loading, error, called } = useQuery(GET_PRODUCT_IN_CART, {
    variables: {
      itemID: cartItem.id,
    },
    //On completed we will have the data and we want to update
    //all the static data in the reactive var and localstorage
    onCompleted: (data) => {
      // localDataUpdate(
      //   data,
      //   cartItem,
      //   cartItems,
      //   cartItemsVar,
      //   localStorageKeys.cartItems,
      //   setDiscounted,
      //   setDiscountedPrice,
      //   locale,
      // )
    },
    onError: (error) => {
      console.log('Error in CartItemComponent')
    },
  })

  //TODO: make better UI for loading state
  if (loading || !data)
    return <Skeleton height="10rem" className={classes.skeleton} />

  if (!data?.findUniqueProductContainer) {
    //-- Delete the product from cart as it is deleted from the system
    removeFromCart(cartItem.id)
    return null
  }

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================

  const { single_product, id } = data.findUniqueProductContainer

  const productProfileIndex: number = getProfileIndexFromLanguage(
    single_product.product_profiles,
    locale,
  )

  //* ============================================================
  //* Here we know if the profile exists or not, if it does
  //* this component can be displayed in the current locale
  //* else this item will be removed from client data by the
  //* above useEffect
  //* =============================================================
  if (single_product.product_profiles[productProfileIndex]) {
    const permalink =
      single_product.product_profiles[productProfileIndex].meta_information
        .permalink
    const name = single_product.product_profiles[productProfileIndex].name
    const price = single_product.price
    const minimum_order_quantity =
      single_product?.minimum_order_quantity ?? null

    const followProductStock = single_product?.follow_product_stock
    const productQuantity = single_product?.quantity

    const quantity = cartItem.quantity

    const images = single_product.images
      .slice()
      .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
    const image = images
      ? images[0]?.src_thumbnail ?? images[0]?.src ?? imageFallback
      : imageFallback

    const discounted = cartItem.discounted
    const discountedPrice = cartItem.discountedPrice

    return (
      <div>
        <div className={classes.cartItem}>
          <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
            <div className={classes.cartItem__imageWrapper}>
              <CardMedia image={image} className={classes.media} />
            </div>
          </Link>
          <div className={classes.cartItem__info}>
            <div className={classes.productName}>
              <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
                <Typography variant="subtitle1">{name}</Typography>
              </Link>
            </div>

            <div className={classes.pricesContainer}>
              <Typography
                variant={discounted ? 'body1' : 'body2'}
                component="p"
                className={clsx(discounted && classes.discoutedPrice)}
              >
                <ProductPrice price={price * quantity} />
              </Typography>
              <Typography variant="subtitle1" component="p">
                {discountedPrice && (
                  <ProductPrice price={discountedPrice * quantity} />
                )}
              </Typography>
            </div>
            <div className={classes.cartItem__actions}>
              <div className={classes.cartItem__quantitySelector}>
                <RemoveIcon
                  className={classes.icon}
                  color={cartItem.quantity === 1 ? `disabled` : `inherit`}
                  onClick={() =>
                    decrementQuantity({
                      cartItemID: cartItem.id,
                      minimum_order_quantity,
                    })
                  }
                />
                <Typography variant="subtitle1">{cartItem.quantity}</Typography>
                <AddIcon
                  className={classes.icon}
                  onClick={() =>
                    incrementQuantity({
                      cartItemID: id,
                      productQuantity,
                      followProductStock,
                      minimum_order_quantity,
                    })
                  }
                />
              </div>
              <Typography
                variant="subtitle2"
                onClick={() => {
                  removeFromCart(id)
                }}
                className={classes.remove}
              >
                {removeCartItem}
              </Typography>
            </div>
          </div>
        </div>
        <Divider />
      </div>
    )
  } else {
    return null
  }
}

const CartItemComponentAdvancedProduct = ({ cartItem }) => {
  if (
    cartItem.advanced_product_data &&
    cartItem.advanced_product_data != null
  ) {
    if (cartItem.advanced_product_data.advanced_product_type === 'SOFA') {
      return <CartItemAdvancedProductSofa cartItem={cartItem} />
      // return <p>Advanced chair component..</p>
    } else if (
      cartItem.advanced_product_data.advanced_product_type ===
        'CHAIR_ARMCHAIR' ||
      cartItem.advanced_product_data.advanced_product_type === 'BED' ||
      cartItem.advanced_product_data.advanced_product_type ===
        'OTHER_WITH_FABRICS' ||
      cartItem.advanced_product_data.advanced_product_type === 'TABLE' ||
      cartItem.advanced_product_data.advanced_product_type === 'LIGHTS' ||
      cartItem.advanced_product_data.advanced_product_type === 'OTHER'
    ) {
      // return <p>Advanced chair component..</p>
      return <CartItemAdvancedProduct cartItem={cartItem} />
    } else {
      return <p>Unhandled advanced product type</p>
    }
  } else {
    return <p>Advanced product data is missing</p>
  }
}

const CartItemAdvancedProductSofa = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const specificationString = t('specification-text')
  const removeCartItem = t('remove-cart-item')
  const classes = useStyles() as any
  // Since cartItems won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()
  const cartItems = useReactiveVar(cartItemsVar)

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const quantity = cartItem.quantity

  //--- ADVANCED SOFA DATA -----
  const selectedFabric = cartItem.advanced_product_data.selected_fabric
  // We neeed to JSON Parse all object in array, because incoming data comes from local storage Stringified...
  const selectedSofaCombinations = parseSofaCombination(
    JSON.parse(
      cartItem.advanced_product_data.selected_sofa_combinations ?? '[]',
    ),
  )

  let elements = [] as any
  let n = 0
  for (const combination of selectedSofaCombinations) {
    elements.push(
      <SofaCombinationCartPreview
        key={n}
        selectedFabric={selectedFabric}
        combination={combination}
      />,
    )
    n++
  }

  const selectedAdditionalComponents =
    cartItem?.advanced_product_data?.selected_additional_components ?? []

  let selectedAdditionalComponentGroups =
    cartItem?.advanced_product_data?.additional_component_groups ?? []

  const selectedAdditionalComponentsWithGroupNames =
    selectedAdditionalComponents.map((component) => ({
      ...component,
      groupName: getComponentGroupName(
        selectedAdditionalComponentGroups,
        component.additionalComponentGroupId,
        locale,
      ),
    }))

  //=== Manage discount
  let discount = null
  if (cartItem?.discounted && cartItem?.price) {
    const discountPercentage =
      100 - (cartItem.discountedPrice * 100) / cartItem.price

    const discountPercentageRounded = Math.round(discountPercentage)
    discount = {
      discount: discountPercentageRounded,
      active: true,
    }
  }

  return (
    <div>
      <div className={classes.cartItem}>
        <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
          <div className={classes.cartItem__imageWrapper}>
            <CardMedia image={image} className={classes.media} />
          </div>
        </Link>
        <div className={classes.cartItem__info}>
          <div className={classes.productName}>
            <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
              <Typography variant="subtitle1">{name}</Typography>
            </Link>
          </div>

          <div className={classes.pricesContainer}>
            <Typography
              variant={discounted ? 'body1' : 'body2'}
              component="p"
              className={clsx(discounted && classes.discoutedPrice)}
            >
              <ProductPrice price={price * quantity} />
            </Typography>
            <Typography variant="subtitle1" component="p">
              {discountedPrice && (
                <ProductPrice price={discountedPrice * quantity} />
              )}
            </Typography>
          </div>

          <div className={classes.cartItem__actions}>
            <div className={classes.cartItem__quantitySelector}>
              <RemoveIcon
                className={classes.icon}
                color={cartItem.quantity === 1 ? `disabled` : `inherit`}
                onClick={() => decrementQuantity({ cartItemID: cartItem.id })}
              />
              <Typography variant="subtitle1">{cartItem.quantity}</Typography>
              <AddIcon
                className={classes.icon}
                onClick={() => incrementQuantity({ cartItemID: id })}
              />
            </div>
            <Typography
              variant="subtitle2"
              onClick={() => {
                removeFromCart(id)
              }}
              className={classes.remove}
            >
              {removeCartItem}
            </Typography>
          </div>
        </div>
      </div>

      <Accordion sx={{ border: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{specificationString}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.advancedPreviewBox}>{elements}</Box>
          {selectedAdditionalComponentsWithGroupNames.map((component, i) => (
            <Box key={component.id}>
              <PreviewSelectedAdditionalComponent
                key={component.id}
                selectedAdditionalComponent={component}
                additonalComponentGroups={selectedAdditionalComponentGroups}
                discount={discount}
                selectedFabric={selectedFabric}
                tiny={true}
              />
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      <Divider />
    </div>
  )
}

const CartItemAdvancedProduct = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const specificationString = t('specification-text')
  const removeCartItem = t('remove-cart-item')
  const classes = useStyles() as any
  // Since cartItems won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()
  const cartItems = useReactiveVar(cartItemsVar)

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const quantity = cartItem.quantity

  //--- ADVANCED SOFA DATA -----
  const selectedFabric = cartItem.advanced_product_data.selected_fabric

  const selectedAdditionalComponents =
    cartItem.advanced_product_data.selected_additional_components

  const discount = cartItem.advanced_product_data.discount

  const additionalComponentGroups =
    cartItem.advanced_product_data.additional_component_groups

  const selectedAdditionalComponentsWithGroupNames =
    selectedAdditionalComponents.map((component) => ({
      ...component,
      groupName: getComponentGroupName(
        additionalComponentGroups,
        component.additionalComponentGroupId,
        locale,
      ),
    }))

  return (
    <div>
      <div className={classes.cartItem}>
        <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
          <div className={classes.cartItem__imageWrapper}>
            <CardMedia image={image} className={classes.media} />
          </div>
        </Link>
        <div className={classes.cartItem__info}>
          <div className={classes.productName}>
            <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
              <Typography variant="subtitle1">{name}</Typography>
            </Link>
          </div>

          <div className={classes.pricesContainer}>
            <Typography
              variant={discounted ? 'body1' : 'body2'}
              component="p"
              className={clsx(discounted && classes.discoutedPrice)}
            >
              <ProductPrice price={price * quantity} />
            </Typography>
            <Typography variant="subtitle1" component="p">
              {discountedPrice && (
                <ProductPrice price={discountedPrice * quantity} />
              )}
            </Typography>
          </div>

          <div className={classes.cartItem__actions}>
            <div className={classes.cartItem__quantitySelector}>
              <RemoveIcon
                className={classes.icon}
                color={cartItem.quantity === 1 ? `disabled` : `inherit`}
                onClick={() => decrementQuantity({ cartItemID: cartItem.id })}
              />
              <Typography variant="subtitle1">{cartItem.quantity}</Typography>
              <AddIcon
                className={classes.icon}
                onClick={() => incrementQuantity({ cartItemID: id })}
              />
            </div>
            <Typography
              variant="subtitle2"
              onClick={() => {
                removeFromCart(id)
              }}
              className={classes.remove}
            >
              {removeCartItem}
            </Typography>
          </div>
        </div>
      </div>

      <Accordion sx={{ border: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{specificationString}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.advancedPreviewBox}>
            <FabricAndAdditionalComponentsCartPreview
              selectedFabric={selectedFabric}
              selectedAddtionalComponents={
                selectedAdditionalComponentsWithGroupNames
              }
              discount={discount}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Divider />
    </div>
  )
}
