import { useReactiveVar } from '@apollo/client'
import { FormControl, Input, MenuItem, Select, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { sortByVar } from '../../../../store/reactiveVars'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      noLabel: {
        marginTop: theme.spacing(3),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
    }),
  { name: 'MuiSortByComponent' },
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function SortByComponent() {
  const { t, i18n } = useTranslation(['common'])
  const sortByString = t('sortBy')
  const sortByNewString = t('sortBy-new')
  const sortByPriceAscString = t('sortBy-priceAsc')
  const sortByPriceDescString = t('sortBy-priceDesc')
  const sortByDispatchString = t('sortBy-dispatch')
  const sortByName = t('sortBy-nameAsc')

  const classes = useStyles() as any
  const sortBy = useReactiveVar(sortByVar)

  const handleChange = (event) => {
    //* ============================================================================================
    //* setting the sortBy reactive var depending on the value selected by user, this value will be
    //* used directly in the query as $orderBy variable.
    //* ============================================================================================
    console.log('event.target.value', event.target.value)
    switch (event.target.value) {
      case '':
        sortByVar({
          queryVar: null,
          value: '',
          orderByName: null,
        })
        break
      case 'nameAscending':
        sortByVar({
          queryVar: null,
          orderByName: 'asc',
          value: 'nameAscending',
        })
        break
      case 'newProducts':
        sortByVar({
          queryVar: {
            createdAt: `desc`,
          },
          value: 'newProducts',
          orderByName: null,
        })
        break
      case 'priceAscending':
        sortByVar({
          queryVar: {
            price_reference: `asc`,
          },
          value: 'priceAscending',
          orderByName: null,
        })
        break
      case 'priceDescending':
        sortByVar({
          queryVar: {
            price_reference: `desc`,
          },
          value: 'priceDescending',
          orderByName: null,
        })
        break
      case 'dispatchTime':
        sortByVar({
          queryVar: {
            dispatch_information: {
              total_delivery_time: `desc`,
            },
          },
          value: 'dispatchTime',
          orderByName: null,
        })
        break
      default:
        console.log(
          "We hit the default case in the sortBy component switch... this shouldn't happen!",
        )
        break
    }
  }

  return (
    <>
      <FormControl className={clsx(classes.formControl, classes.noLabel)}>
        <Select
          displayEmpty
          value={sortBy.value}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': sortByString }}
        >
          <MenuItem value="">{sortByString}</MenuItem>
          <MenuItem value="nameAscending">{sortByName}</MenuItem>
          <MenuItem value="newProducts">{sortByNewString}</MenuItem>
          <MenuItem value="priceAscending">{sortByPriceAscString}</MenuItem>
          <MenuItem value="priceDescending">{sortByPriceDescString}</MenuItem>
          <MenuItem value="dispatchTime">{sortByDispatchString}</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}
