import { useLazyQuery, useReactiveVar } from '@apollo/client'
import {
  Button,
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  Radio,
  RadioGroup,
  Switch,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useShopContext } from '../../../context'
import { GET_SHOPSETTINGS_VAT_CODE } from '../../../operations/queries'
import { orderVar } from '../../../store/reactiveVars'
import isCompanyInSameCountry from '../../../utilityFunctions/isCompanyInSameCountry'
import { useAppContext } from '../../../context/appContext'
import Link from 'next/link'
import { TENANT_VILMERS } from '../../../constants/featureFlags'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      pageTitle: {
        fontSize: 32,
        fontWeight: 500,
      },
      gridContainer: {
        margin: theme.spacing(2, 0, 1, 0),
        [theme.breakpoints.down('lg')]: {
          maxWidth: '100%',
        },
      },
      input: {
        border: '1px solid #d3d3d3',
        padding: theme.spacing(1.3, 1),
      },
      inputLabel: {
        marginBottom: theme.spacing(0.5),
      },
      radioGroup: {
        display: 'flex',
        flexDirection: 'row',
      },
      button: {
        width: '40%',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      },
      error: {
        color: '#d9534f',
      },
      accordion: {
        marginBottom: theme.spacing(2),
        border: 0,
        backgroundColor: theme.palette.background.paper,
      },
      buyingAsCompanyLabel: {
        marginLeft: 0,
      },
    }),
  { name: 'MuiDeliveryContactFormComponent' },
)

/// VALIDATION =========
// const useYupValidationResolver = (validationSchema) =>
//   useCallback(
//     async (data) => {
//       try {
//         const values = await validationSchema.validate(data, {
//           abortEarly: false,
//         });

//         return {
//           values,
//           errors: {},
//         };
//       } catch (errors) {
//         return {
//           values: {},
//           errors: errors.inner.reduce(
//             (allErrors, currentError) => ({
//               ...allErrors,
//               [currentError.path]: {
//                 type: currentError.type ?? "validation",
//                 message: currentError.message,
//               },
//             }),
//             {}
//           ),
//         };
//       }
//     },
//     [validationSchema]
//   );

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// const validationSchema = yup.object({
//   firstName: yup.string().required(),
//   lastName: yup.string().required(),
//   email: yup.string().email().required("emailInputLabel"),
//   phone: yup.string().required(),
//   // .matches(phoneRegExp, "Phone number is not valid"),  // Not working for LT numbers...
// });

export default function DeliveryContactForm({ handleNextSubStep }) {
  const { t } = useTranslation(['common'])
  const formOneStepOneHeader = t('form-one-step-one-header')
  const firstNameInputLabel = t('first-name-input-label')
  const lastNameInputLabel = t('last-name-input-label')
  const emailInputLabel = t('email-input-label')
  const phoneInputLabel = t('phone-input-label')
  const buyingAsCompanyString = t('buying-as-company-toggle')
  const companyInputNameLabel = t('company-input-name-label')
  const companyInputCodeLabel = t('company-input-code-label')
  const companyInputVatCodeLabel = t('company-input-vat-code-label')
  const newsletterConsentString = t('newsletter-consent')
  const chooseOneOptionString = t('please-choose-option')
  const fieldRequiredError = t('field-is-required')
  const yesString = t('yes')
  const noString = t('no')

  const agreeTermsAndConditionsString = t('agree-with-terms-and-conditions')
  const termsAndConditionsString = t('terms-and-conditions')

  const nextStepButtonString = t('next-step-button')
  const classes = useStyles() as any
  const { SHOP_ID } = useShopContext()
  //I'm using the state because I will call the lazy query
  //only in the case the user will be buying as a company
  //I need to be sure that I already have the data from the server
  //and since it's an async operation and the query returns => void instead of a promise
  //(https://github.com/apollographql/apollo-feature-requests/issues/174)

  // const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    getValues,
    setValue,
  } = useForm({
    // resolver,
    // defaultValues: {
    //   firstName: '',
    //   lastName: '',
    //   email: '',
    //   phone: '',
    //   company_name: '',
    //   company_code: '',
    //   vat_code: '',
    //   promo: 'yes',
    // },
  })

  const errors = formState.errors

  const order = useReactiveVar(orderVar)
  const [buyingAsCompany, setBuyingAsCompany] = useState(
    order.buying_as_company ?? false,
  )

  const [acceptedTermsConditions, setAcceptedTermsConditions] = useState(true)
  // const [tempFormData, setTempFormData] = useState(undefined)

  //* =================================================================================
  //* ================= GET THIS SHOP DEFAULT MANUFACTURER VAT CODE ===================
  //* =================================================================================
  const [getDefaultManufacturerVATCode, { data, loading, called }] =
    useLazyQuery(GET_SHOPSETTINGS_VAT_CODE, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        let formValues = getValues()

        // TODO ---- -THIS IS AN UGLY FIX... NEED TO FIX THIS....
        if (formValues.firstName === undefined) {
          formValues = order
        }

        const defaultManufacturerVATCode =
          data.findFirstShopSetting.default_manufacturer?.vat_code
        const buyingCompanyVATCode = formValues.vat_code

        formValues.promo = formValues.promo === 'no' ? false : true

        let is_lithuanian_company = isCompanyInSameCountry(
          defaultManufacturerVATCode,
          buyingCompanyVATCode,
        )

        let companyInfoObject = {
          buying_as_company: buyingAsCompany,
          is_lithuanian_company,
          zero_vat: !is_lithuanian_company,
        }

        orderVar({
          ...order,
          ...formValues,
          ...companyInfoObject,
        })

        handleNextSubStep()
      },
      onError: () => {
        console.log('Error while retrieving the VAT code of this merchant!')
      },
    })

  //* ====================================================
  //* ================= HANDLE SUBMIT ====================
  //* ====================================================
  const onSubmit = (formData) => {
    formData.promo = formData.promo === 'no' ? false : true
    // setTempFormData(formData)
    // I want to add "is_lithuanian_company" only if the user is buying as company
    if (buyingAsCompany) {
      getDefaultManufacturerVATCode({
        variables: {
          SHOP_ID,
        },
      })
    } else {
      let companyInfoObject = {
        buying_as_company: buyingAsCompany,
        is_lithuanian_company: true,
        zero_vat: false,
      }
      //if user is not buying as company, but filled the form previously
      //we want to make sure that formData, doesn't go through again
      // so we clean both the order reactive var and the formData coming from the form
      //clean order

      order.company_name = null
      order.company_code = null
      order.vat_code = null
      order.is_lithuanian_company = null

      //clean form react-hook-form
      delete formData.company_name
      delete formData.company_code
      delete formData.vat_code
      delete formData.is_lithuanian_company

      // this is quick hack to fix the issue with the email and phone number not being saved, cause of missing formData values
      if (!b2bToken) {
        orderVar({
          ...order,
          ...formData,
          companyInfoObject,
        })
      }

      handleNextSubStep()
    }
  }

  //* ======================================================
  //* ============ Handle Buying as company ================
  //* ======================================================
  const handleBuyingAsCompany = (e) => {
    setBuyingAsCompany(e.target.checked)
    orderVar({
      ...order,
      buying_as_company: e.target.checked,
    })
  }

  ///====
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  useEffect(() => {
    if (b2bToken) {
      setBuyingAsCompany(true)

      order.firstName = b2bToken.name ?? '-'
      order.lastName = b2bToken.surname ?? '-'
      order.email = b2bToken.email ?? '-'
      order.phone =
        b2bToken?.default_phone_number != '' && b2bToken?.default_phone_number
          ? b2bToken?.default_phone_number
          : b2bToken?.b2b_company_address?.phone_number != ''
          ? b2bToken?.b2b_company_address?.phone_number
          : '-'
      order.company_name = b2bToken.b2b_company_name ?? '-'
      order.company_code = b2bToken.company_code ?? '-'
      order.vat_code = b2bToken.vat_code ?? '-'

      order.address1 = b2bToken.b2b_company_address?.address_1 ?? '-'
      order.address2 = b2bToken.b2b_company_address?.address_2 ?? '-'
      order.city = b2bToken.b2b_company_address?.city ?? '-'
      order.country = b2bToken.b2b_company_address?.country ?? '-'
      order.postal_code = b2bToken.b2b_company_address?.postal_code ?? '-'
      order.state = b2bToken.b2b_company_address?.state_region ?? '-'

      order.billing_address1 = b2bToken.b2b_company_address?.address_1 ?? '-'
      order.billing_address2 = b2bToken.b2b_company_address?.address_2 ?? '-'
      order.billing_city = b2bToken.b2b_company_address?.city ?? '-'
      order.billing_country = b2bToken.b2b_company_address?.country ?? '-'
      order.billing_postal_code =
        b2bToken.b2b_company_address?.postal_code ?? '-'

      console.log('order :>> ', order)
      order.metadata = {
        intengrationID: TENANT_VILMERS,
      }

      orderVar({
        ...order,
        // ...billingData,
        buying_as_company: true,
      })
    }
  }, [b2bToken])

  useEffect(() => {
    if (b2bToken) {
      setValue('firstName', b2bToken.name ?? '-')
      setValue('lastName', b2bToken.surname ?? '-')
      setValue('email', b2bToken.email ?? '-')
      setValue(
        'phone',
        b2bToken?.default_phone_number != ''
          ? b2bToken?.default_phone_number
          : b2bToken?.b2b_company_address?.phone_number != ''
          ? b2bToken?.b2b_company_address?.phone_number
          : '-',
      )
      setValue('company_name', b2bToken.b2b_company_name ?? '-')
      setValue('company_code', b2bToken.b2b_company_code ?? '-')
      setValue('vat_code', b2bToken.b2b_vat_code ?? '-')
    }
  }, [control, order])

  const disableInputFields = b2bToken ? true : false
  const fieldRequired = b2bToken ? false : fieldRequiredError
  // const disableInputFields = false

  return (
    <form id="customer_details_form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        {formOneStepOneHeader}
      </Typography>
      <Divider />
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={6} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {firstNameInputLabel}
              {errors.firstName ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            classes={{
              input: classes.input,
            }}
            fullWidth
            autoComplete="given-name"
            inputProps={{
              ...register('firstName', {
                required: fieldRequired,
              }),
            }}
            defaultValue={
              'firstName' in order && order.firstName ? order.firstName : ''
            }
            disabled={disableInputFields}
          />
          {errors.firstName && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.firstName.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {lastNameInputLabel}
              {errors.lastName ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="family-name"
            inputProps={{
              ...register('lastName', {
                required: fieldRequired,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'lastName' in order && order.lastName ? order.lastName : ''
            }
            disabled={disableInputFields}
          />
          {errors.lastName && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.lastName.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {emailInputLabel}
              {errors.email ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="email"
            inputProps={{
              ...register('email', { required: fieldRequired }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'email' in order && order.email ? order.email : ''}
            disabled={disableInputFields}
          />
          {errors.email && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.email.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {phoneInputLabel}
              {errors.phone ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="tel"
            inputProps={{
              ...register('phone', { required: fieldRequired }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'phone' in order && order.phone ? order.phone : ''}
            disabled={disableInputFields}
          />
          {errors.phone && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.phone.message}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={buyingAsCompany}
                onChange={handleBuyingAsCompany}
                color="primary"
              />
            }
            label={buyingAsCompanyString}
            labelPlacement="start"
            classes={{
              labelPlacementStart: classes.buyingAsCompanyLabel,
            }}
          />
        </Grid>

        {buyingAsCompany && (
          <>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <Typography variant="body1" component="p">
                  {companyInputNameLabel}
                  {errors.company_name ? (
                    <span className={classes.error}>*</span>
                  ) : (
                    `*`
                  )}
                </Typography>
              </InputLabel>
              <InputBase
                fullWidth
                autoComplete="org"
                inputProps={{
                  ...register('company_name', {
                    required: fieldRequired,
                  }),
                }}
                classes={{
                  input: classes.input,
                }}
                defaultValue={
                  'company_name' in order && order.company_name
                    ? order.company_name
                    : ''
                }
                disabled={disableInputFields}
              />
              {errors.company_name && (
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.error}
                >
                  {errors.company_name.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} sm={6}>
              <InputLabel className={classes.inputLabel}>
                <Typography variant="body1" component="p">
                  {companyInputCodeLabel}
                  {errors.company_code ? (
                    <span className={classes.error}>*</span>
                  ) : (
                    `*`
                  )}
                </Typography>
              </InputLabel>
              <InputBase
                classes={{
                  input: classes.input,
                }}
                fullWidth
                inputProps={{
                  ...register('company_code', {
                    required: fieldRequired,
                  }),
                }}
                defaultValue={
                  'company_code' in order && order.company_code
                    ? order.company_code.toString()
                    : ''
                }
                disabled={disableInputFields}
              />
              {errors.company_code && (
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.error}
                >
                  {errors.company_code.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} sm={6}>
              <InputLabel className={classes.inputLabel}>
                <Typography variant="body1" component="p">
                  {companyInputVatCodeLabel}
                  {errors.vat_code ? (
                    <span className={classes.error}>*</span>
                  ) : (
                    `*`
                  )}
                </Typography>
              </InputLabel>
              <InputBase
                classes={{
                  input: classes.input,
                }}
                fullWidth
                inputProps={{
                  ...register('vat_code', {
                    // required: fieldRequired,
                  }),
                }}
                defaultValue={
                  'vat_code' in order && order.vat_code ? order.vat_code : ''
                }
                disabled={disableInputFields}
              />
              {errors.vat_code && (
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.error}
                >
                  {errors.vat_code.message}
                </Typography>
              )}
            </Grid>
          </>
        )}

        {!b2bToken && (
          <>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Typography variant="caption">
                  {newsletterConsentString}
                  {errors.promo ? (
                    <span className={classes.error}>*</span>
                  ) : (
                    `*`
                  )}
                </Typography>

                <Controller
                  name="promo"
                  control={control}
                  defaultValue=""
                  rules={{ required: chooseOneOptionString }}
                  render={({ field: { onChange, ref, value }, fieldState }) => {
                    return (
                      <RadioGroup
                        aria-label="promo"
                        className={classes.radioGroup}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              value="yes"
                              name="promoYes"
                              onChange={(e) => onChange(e.target.value)}
                              inputRef={ref}
                            />
                          }
                          label={yesString}
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value="no"
                              name="promoNo"
                              onChange={(e) => onChange(e.target.value)}
                              inputRef={ref}
                            />
                          }
                          label={noString}
                        />
                      </RadioGroup>
                    )
                  }}
                />
                {errors.promo && (
                  <Typography
                    variant="caption"
                    component="span"
                    className={classes.error}
                  >
                    {errors.promo.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Typography variant="caption">
                  {agreeTermsAndConditionsString}{' '}
                  {errors.promo ? (
                    <span className={classes.error}>*</span>
                  ) : (
                    `*`
                  )}
                  <a>
                    <Link href={'/terms-conditions'}>
                      <Typography variant="caption" color="secondary">
                        {termsAndConditionsString}
                      </Typography>
                    </Link>
                  </a>
                </Typography>

                <Controller
                  name="termsAndConditionsRadio"
                  control={control}
                  defaultValue=""
                  rules={{ required: chooseOneOptionString }}
                  render={({ field: { onChange, ref, value }, fieldState }) => {
                    return (
                      <RadioGroup
                        aria-label="termsAndConditionsRadio"
                        className={classes.radioGroup}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              value="yes"
                              name="termsYes"
                              onChange={(e) => {
                                onChange(e.target.value)
                                setAcceptedTermsConditions(true)
                              }}
                              inputRef={ref}
                            />
                          }
                          label={yesString}
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value="no"
                              name="termsNo"
                              onChange={(e) => {
                                onChange(e.target.value)
                                setAcceptedTermsConditions(false)
                              }}
                              inputRef={ref}
                            />
                          }
                          label={noString}
                        />
                      </RadioGroup>
                    )
                  }}
                />
                {errors.promo && (
                  <Typography
                    variant="caption"
                    component="span"
                    className={classes.error}
                  >
                    {errors.promo.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>

      <Button
        variant="contained"
        type="submit"
        className={classes.button}
        disabled={!acceptedTermsConditions}
      >
        {nextStepButtonString}
      </Button>
    </form>
  )
}
