import {
  CardActions,
  CardContent,
  createStyles,
  Link,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { ProductPrice } from '../../UtilityComponents/price'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import clsx from 'clsx'

const showPrice =
  process.env.NEXT_PUBLIC_SHOW_PRICE_IN_PRODUCT_LIST === 'true' ||
  !process.env.NEXT_PUBLIC_SHOW_PRICE_IN_PRODUCT_LIST

interface ProductCardFooterProps {
  price: number
  discount: number
  //currency: string
  link: string
  productName: string
  children?: React.ReactNode
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      cardContent: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2, 0),
      },

      namePriceContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
          flexDirection: 'column',
        },
      },
      productName: {
        [theme.breakpoints.down('lg')]: {
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': 1,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        },
      },

      pricesContainer: {
        display: 'flex',
        gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
      },
    }),
  { name: 'MuiProductCardPriceComponent' },
)

const ProductCardFooter: React.FC<ProductCardFooterProps> = ({
  price,
  discount,
  // currency,
  link,
  productName,
  children,
}) => {
  const { discounted, discountedPrice } = checkDiscountValidity(discount, price)
  const classes = useStyles() as any

  return (
    <CardContent className={classes.cardContent}>
      <div className={classes.namePriceContainer}>
        <Link href={link}>
          <span className="cursorPointer">
            <Typography
              gutterBottom
              variant="body1"
              component="h2"
              className={classes.productName}
            >
              {productName}
            </Typography>
          </span>
        </Link>
        {showPrice && (
          <div className={classes.pricesContainer}>
            <Typography
              variant={discounted ? 'body1' : 'body2'}
              component="p"
              className={clsx(discounted && classes.discoutedPrice)}
            >
              <ProductPrice price={price} />
            </Typography>
            <Typography variant="subtitle1" component="p">
              {discountedPrice && <ProductPrice price={discountedPrice} />}
            </Typography>
          </div>
        )}
      </div>

      {children}
    </CardContent>
  )
}

export default ProductCardFooter
