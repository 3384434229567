// Applies conditions if exist, and filters the additional components that mets the conditions
export const getValidAdditionalComponents = (
  componentGroupToFilter,
  selectedComponents,
  selectedSofaCombinations = null,
) => {
  const filteredByCompoentCondition =
    componentGroupToFilter?.additional_components?.filter((component) => {
      if (!component.conditions) {
        return true
      }

      const { onlyWithComponents, onlyWithComponentsGroup } =
        component.conditions

      if (onlyWithComponents) {
        const selectedMulti = selectedComponents
          .filter((item) => item.groupCode.startsWith(onlyWithComponentsGroup))
          ?.map((item) => item.code)

        //onlyWithComponents should include all values listed in selectedMulti
        return selectedMulti.every((value) =>
          onlyWithComponents.includes(value),
        )
      }

      return true
    })
  let legsByModules

  // FIltering components based on modules metadata it only applies if metadata has a property with name same as componentGroupToFilter.code
  if (selectedSofaCombinations && componentGroupToFilter?.code) {
    // using legs as component name, but it's based on group code, so it's not always legs
    selectedSofaCombinations.forEach((sofaCombination) =>
      sofaCombination.forEach((module) => {
        // console.log(
        //   'module',
        //   module?.attrs?.originalSofaForm?.code,
        //   module?.attrs?.originalSofaForm?.metadata?.[
        //     componentGroupToFilter.code
        //   ],
        // )
        const suportedComponents =
          module?.attrs?.originalSofaForm?.metadata?.[
            componentGroupToFilter.code
          ]
        if (!suportedComponents) return

        if (legsByModules == undefined) {
          legsByModules = [...suportedComponents]
          return
        }

        // filter out legs list and keep here only the common legs
        legsByModules = legsByModules.filter((leg) =>
          suportedComponents.some((suportedComponent) => {
            return leg.code === suportedComponent.code
          }),
        )
      }),
    )

    const legscodes = legsByModules?.map((leg) => leg.code)
    let filteredComponents
    if (legsByModules) {
      filteredComponents = filteredByCompoentCondition.filter((component) => {
        return legscodes.includes(component.code)
      })

      return filteredComponents
    }
  }

  return filteredByCompoentCondition
}
