import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { SentryLink } from 'apollo-link-sentry'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient

let graphQLEndpoint
if (process.env.NODE_ENV === 'production') {
  // ----
  graphQLEndpoint = process.env.NEXT_PUBLIC_BACKEND
} else {
  graphQLEndpoint = process.env.NEXT_PUBLIC_BACKEND_DEV
}

// console.log("graphQLEndpoint ->", graphQLEndpoint);

// ----- Fucks up ServerSide props, keep getting old data...
// if (typeof window !== "undefined") {
//   persistCache({
//     cache,
//     storage: new SessionStorageWrapper(window.sessionStorage),
//   });
// }

//Fetch policy merge function
function productContainersMerge(existing, incoming, readField, mergeObjects) {
  const merged: any[] = existing ? existing.slice(0) : []
  //* this creates an object Record<K, T> where K is the type of all the keys and T is the type of all the values
  const productToIndex: Record<number, number> = Object.create(null)
  //* ================= If "existing" is defined we loop through it and for each product we create a key value pair where key is the productID and the value is the index of that id in the "existing" array. =================
  if (existing) {
    existing.forEach((product, index) => {
      productToIndex[readField('id', product)] = index
    })
  }
  //* ============ We always loop through the "incoming" array, we get the id of each product and his index from the "productToIndex" object. We have to check if it exists and if the type of the index is number.
  incoming.forEach((product) => {
    const productID = readField('id', product)
    const index = productToIndex[productID]
    if (typeof index === 'number') {
      // Merge the new product data with the existing product data. The mergeObjects helper function will check if the __ref is the same in the two objects and if it is, it will safely merge the two.
      merged[index] = mergeObjects(merged[index], product)
    } else {
      // It's the first time we see this product so we add it the productToIndex object, assigning to the key the product ID and to the value the length of the merged array
      productToIndex[productID] = merged.length
      merged.push(product)
    }
  })
  return merged
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      new SentryLink({
        uri: graphQLEndpoint,
        setTransaction: true,
        setFingerprint: true,
        attachBreadcrumbs: {
          includeVariables: true,
          includeError: true,
          includeFetchResult: false,
        },
      }),

      createHttpLink({
        uri: graphQLEndpoint,
        // uri: "http://192.168.1.183:4000/graphql", // Server URL (must be absolute)
        credentials: 'same-origin',
        fetch,
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            sortedProductContainersData: {
              // keyArgs: ["where", "orderBy", "cursor", "first", "loadMore"],

              merge(existing, incoming, { mergeObjects, readField, args }) {
                const products = productContainersMerge(
                  existing?.sortedProductContainers,
                  incoming.sortedProductContainers,
                  readField,
                  mergeObjects,
                )

                return {
                  lastProductId: incoming.lastProductId,
                  hasNextPage: incoming.hasNextPage,
                  sortedProductContainers: products,
                }
              },

              read(existing, { args }) {
                if (existing) {
                  return existing
                }
              },
            },

            findManyProductContainerExtendedSorting: {
              keyArgs: ['where', 'orderBy', 'orderByName'],

              merge(
                existing: any[],
                incoming: any[],
                { readField, mergeObjects, args },
              ) {
                console.log(
                  'findManyProductContainerExtendedSorting merge ->',
                  { existing, incoming },
                )
                const merged: any[] = existing ? existing.slice(0) : []
                //* this creates an object Record<K, T> where K is the type of all the keys and T is the type of all the values
                const productToIndex: Record<number, number> =
                  Object.create(null)

                //* ================= If "existing" is defined we loop through it and for each product we create a key value pair where key is the productID and the value is the index of that id in the "existing" array. =================
                if (existing) {
                  existing.forEach((product, index) => {
                    productToIndex[readField<number>('id', product)] = index
                  })
                }

                //* ============ We always loop through the "incoming" array, we get the id of each product and his index from the "productToIndex" object. We have to check if it exists and if the type of the index is number.
                incoming.forEach((product) => {
                  const productID = readField<number>('id', product)
                  const index = productToIndex[productID]
                  if (typeof index === 'number') {
                    // Merge the new product data with the existing product data. The mergeObjects helper function will check if the __ref is the same in the two objects and if it is, it will safely merge the two.
                    merged[index] = mergeObjects(merged[index], product)
                  } else {
                    // It's the first time we see this product so we add it the productToIndex object, assigning to the key the product ID and to the value the length of the merged array
                    productToIndex[productID] = merged.length
                    merged.push(product)
                  }
                })
                return merged
              },
            },

            cartItems: {
              keyArgs: false,
              merge(existing, incoming) {
                return incoming
              },
            },
            wishlistItems: {
              keyArgs: false,
              merge(existing, incoming) {
                return incoming
              },
            },
            recentlyViewed: {
              keyArgs: false,
              merge(existing, incoming) {
                return incoming
              },
            },

            // sortedByCategoryPositionProductContainersData: {
            //   // keyArgs: ["where", "orderBy", "cursor", "first", "loadMore"],

            //   merge(existing, incoming, { mergeObjects, readField, args }) {
            //     const products = productContainersMerge(
            //       existing?.sortedProductContainers,
            //       incoming.sortedProductContainers,
            //       readField,
            //       mergeObjects
            //     );

            //     return {
            //       cursor: incoming.cursor,
            //       hasNextPage: incoming.hasNextPage,
            //       sortedProductContainers: products,
            //     };
            //   },

            //   read(existing, { args }) {
            //     if (existing) {
            //       return existing;
            //     }
            //   },
            // },
          },
        },
      },
    }),
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // console.log("Initializing apollo client....");
  // console.log(" typeof window ->", typeof window === "undefined");
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
