// --- The lines ---
export const METRIC_SIZE = 50
export const MAIN_METRIC_COLOR = '#D3D3D3'

// -- Shape sizes
export const ARMS_REST_WIDTH = 22
export const BACK_REST_WIDTH = 25
// -- SOFA BEDS
export const MATTRESS_WIDTH = 140
export const MATTRESS_LENGTH = 180
// --- EXTENDABLE SHAPEs
export const EXTENTABLE_PART_LENGTH = 50

//--- -
export const SEAT_HEIGHT = 40

export const LCH_ARMSREST_LENGTH_PERCETAGE = 0.5
export const OTK_ARMSREST_LENGTH_PERCETAGE = 0.6
export const SLEEP_PART_LENGTH_PERCETAGE = 0.5
export const LCH_INCURVE_PERCENTAGE = 0.25
export const LCH_ANGLE_ARMSREST_LENGTH_PERCETAGE = 0.75
export const INSIDE_BACKREST_MARGIN_PERCENTAGE = 0.15

// export const ARMS_REST_WIDTH = 20
// export const BACK_REST_WIDTH = 10

// -- Shape Colors
// export const MAIN_SHAPE_COLOR = '#D3D3D3'
export const MAIN_SHAPE_COLOR = '#e2e1e0'
export const TABLE_COLOR = '#b2b0b0'
export const BED_FORM_COLOR = '#fff'

export const MAIN_SHAPE_SHADOW_COLOR = '#D3D3D3'
export const SHADOW_WIDTH = 6
export const GREEN_SHAPE_COLOR = 'rgb(144, 238, 144, 0.5)'
export const RED_SHAPE_COLOR = 'rgb(255,0,0, 0.5)'

// ----  Rounded stuff
export const ANGLE_RADIUS = 0.25
export const CURVER_ANGLE_RADIUS = 0.5
export const POLYGON_CUT_PERCENTAGE = 0.6
export const POLYGON_LCH_CUT_PERCENTAGE = 0.35
export const POLYGON_ANGLE = 0.35

export const ANGLE_CORNER_ANGLE = 35

//--- PILLOWS
export const STANDART_PILLOW_SIZE = 50
export const BACKREST_PILLOW_SIZE = 60
