import { gql } from '@apollo/client'
import { PRODUCT_FRAGMENT } from './categoryCatchAllQueries'

export const GET_FILTERED_PRODUCTS = gql`
  query GET_FILTERED_PRODUCTS(
    # $permalink: String
    # --------
    $colors: [SingleProductWhereInput]
    $frameColors: [SingleProductWhereInput]
    $language: Language
    $dimensions: [DimensionWhereInput]
    $price: ProductContainerWhereInput
    $cursor: ProductContainerWhereUniqueInput
    $orderBy: [ProductContainerOrderByWithRelationInput!]
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
    $customers: ProductContainerWhereInput
    $skip: Int
    $orderByName: SortOrder
    $searchFilter: StringFilter
  ) {
    findManyProductContainerExtendedSorting(
      take: 32
      #after: $lastProductID
      cursor: $cursor
      skip: $skip
      orderBy: $orderBy
      orderByName: $orderByName
      language: $language
      where: {
        AND: [
          $customers
          { visible: { equals: true } }
          { OR: $productTypes }
          { OR: $styles }
          { OR: $categories }
          $price
          {
            OR: [
              {
                single_product: {
                  is: {
                    AND: [
                      { dimensions: { is: { AND: $dimensions } } }
                      { OR: $colors }
                      { OR: $frameColors }
                      {
                        product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                                              {
                        product_profiles: {
                          some: { name: $searchFilter }
                        }
                      }
                    ]
                  }
                }
              }
              {
                advanced_product: {
                  is: {
                    AND: [
                      {
                        advanced_product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                      {
                        advanced_product_profiles: {
                          some: { name: $searchFilter }
                        }
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      }
    ) {
      id
    }
    findManyProductContainerCount(
      where: {
        AND: [
          $customers
          { visible: { equals: true } }
          { OR: $productTypes }
          { OR: $styles }
          { OR: $categories }
          $price
          {
            OR: [
              {
                single_product: {
                  is: {
                    AND: [
                      { dimensions: { is: { AND: $dimensions } } }
                      { OR: $frameColors }
                      { OR: $colors }
                      {
                        product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                                                                      {
                        product_profiles: {
                          some: { name: $searchFilter }
                        }
                      }
                    ]
                  }
                }
              }
              {
                advanced_product: {
                  is: {
                    AND: [
                      {
                        advanced_product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                                             {
                        advanced_product_profiles: {
                          some: { name: $searchFilter }
                        }
                      }
                    ]
                  }
                }
              }
            ]
          }
          { OR: [] }
          { OR: [] }
        ]
      }
    )
  }
`

export const GET_PRODUCT_CONTAINERS = gql`
  ${PRODUCT_FRAGMENT}
  query GET_PRODUCT_CONTAINERS(
    $take: Int
    $where: ProductContainerWhereInput
    $orderBy: ProductContainerOrderByWithRelationInput
    $loadMore: Boolean!
    $cursor: ProductContainerWhereUniqueInput
    $skip: Int
    $orderByName: SortOrder
    $language: Language
  ) {
    sortedProductContainersData(
      skip: $skip
      take: $take
      cursor: $cursor
      orderBy: $orderBy
      orderByName: $orderByName
      language: $language
      where: $where
    ) {
      sortedProductContainers {
        id
        ...PRODUCT @skip(if: $loadMore)
      }
      lastProductId
      hasNextPage
    }
  }
`
