import { Box, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PreviewSelectedFabric from '../ProductPageElements/AdvancedProductElements/Components/PreviewSelectedFabric'
import PreviewSelectedAdditionalComponent from '../ProductPageElements/AdvancedProductElements/Components/PreviewSelectedAdditionalComponent'

const useStylesCombinationPreview = makeStyles(
  (theme) => ({
    root: {
      // padding: '10px',
      overflow: 'hidden',
    },

    drawingBox: {
      width: '100%',
      height: '200px',
    },
  }),
  { name: 'MuiSFabricAndAdditionalComponentsCartPreviewcomponent' },
)

const FabricAndAdditionalComponentsCartPreview = ({
  selectedFabric,
  selectedAddtionalComponents,
  discount,
  ...props
}) => {
  const classes = useStylesCombinationPreview() as any

  return (
    <>
      <Divider />
      <div className={classes.root}>
        {selectedFabric.fabricObject && (
          <PreviewSelectedFabric
            selectedAdvancedProductFabric={selectedFabric}
            elevation={0}
          />
        )}
        {selectedAddtionalComponents.map((component, i) => (
          <PreviewSelectedAdditionalComponent
            key={component.id}
            selectedAdditionalComponent={component}
            discount={discount}
            elevation={0}
            selectedFabric={selectedFabric}
            tiny={true}
          />
        ))}
      </div>
    </>
  )
}

export default FabricAndAdditionalComponentsCartPreview
